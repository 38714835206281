import {STANDARD_UNLIMITED_VND_PRICE} from './trial-subscription/common'

const MILISEC_ONE_DAY = 24 * 3600 * 1000
const SUBIZ_BANK_ACCOUNT_ID = '0451001477880'

export default {
	name: 'ExpiredWarningModal',
	data() {
		return {
			showExpModal: false,
			modalType: 'churned', // churned, offer. churned is show modal about churn notification, offer is show modal with unlimited agent offer with purchase tutorial
		}
	},
	mounted() {
		this.shouldShowExpModal()
	},
	watch: {
		$route(to, from) {
			this.shouldShowExpModal()
		},
	},
	methods: {
		getTransferQrCode() {
			let amount = STANDARD_UNLIMITED_VND_PRICE
			let desc = this.getContentTransfer()
			let src = `https://img.vietqr.io/image/VCB-${SUBIZ_BANK_ACCOUNT_ID}-qr_only.png?amount=${amount}&addInfo=${desc}&accountName=CONG%20TY%20CO%20PHAN%20TRUC%20TUYEN%20VIETNAMBIZ`

			return src
		},

		getContentTransfer() {
			let accid = ''
			let acc = store.me().account
			let owner = store.matchAgent()[acc.owner_id] || {}
			if (!accid) accid = lo.get(owner, 'email')
			if (!accid) accid = lo.get(owner, 'phone')
			if (!accid) {
				accid = lo.get(store.me(), 'account.id', '')
			}
			accid = (accid || '').replace('@', '').replace('.', '')
			return accid
		},

		shouldShowExpModal() {
			let router_names = [
				'subscriptions-page',
				'invoices',
				'billing',
				'trial.subscriptions',
				'trial.invoice-payment',
				'trial.invoice-detail',
			]
			let routerName = lo.get(this.$route, 'name', '')
			if (lo.includes(router_names, routerName)) {
				this.showExpModal = false
				return
			}

			let subscription = store.matchSubscription()
			let plan = lo.get(subscription, 'plan')
			let isOwner = store.me().id === lo.get(store.me(), 'account.owner_id')

			if (plan === 'trial' || !plan) {
				let trial_end_time = lo.get(subscription, 'ended') || 0
				trial_end_time = sb.getMs(trial_end_time)
				let remaining_trial_time = trial_end_time - Date.now()
				let isSkip = Date.now() - store.getSkipExpModalTime() < MILISEC_ONE_DAY

				if (remaining_trial_time < 3 * MILISEC_ONE_DAY && isOwner && !isSkip) {
					this.showExpModal = true
					this.modalType = 'offer'
					let time_left = Math.round(remaining_trial_time / (24 * 3_600_000))
					if(time_left > 0 ) this.trial_day_left = time_left
					else this.trial_day_left = 0
					store.addClarityTracingScript()
					setTimeout(() => {
						if (window.clarity) {
							window.clarity('set', 'sbz_record_type', 'trial_expired')
						}
					}, 100)

					return
				}
			}
			if (subscription.churned) {
				this.showExpModal = true
				this.modalType = 'churned'
				store.addClarityTracingScript()
				setTimeout(() => {
					if (window.clarity) {
						window.clarity('set', 'sbz_record_type', 'churned')
					}
				}, 100)
				return
			}

			this.showExpModal = false
		},

		onCloseExpModal() {
			this.showExpModal = false
			store.setSkipExpModalTime(Date.now())
		},

		renderContent() {
			if (this.modalType === 'offer') {
				let $title = (
					<div
						class='text__semibold text__xl'
						domPropsInnerHTML={this.$t('trial_exp_left_title', [this.trial_day_left])}
					></div>
				)
				let $desc = this.$t('trial_exp_modal_desc')
				let subs = lo.cloneDeep(store.matchSubscription())

				if (subs.churned) {
					$title = <div class='text__semibold text__danger text__xl'>{this.$t('churned_trial_title')}</div>
					$desc = this.$t('churned_trial_desc')
				}

				return (
					<div class='modal_content' style='width: 550px;'>
						<div class='trial_warning_modal_wrapper'>
							<div class='d-flex justify-content-between align-items-center'>
								{$title}
								<div
									class='btn__close d-flex align-items-center justify-content-center'
									vOn:click={() => this.onCloseExpModal()}
								>
									<Icon name='x' size='16' />
								</div>
							</div>
							<div class='text__muted' style='margin-top: 15px'>
								{$desc}
							</div>

							<div class='mt-4 d-flex justify-content-between align-items-center'>
								<div class='text__semibold'>{this.$t('rcm_sub_plan')}</div>
								<div
									class='link link__primary'
									vOn:click={() => {
										this.onCloseExpModal()
										this.$router.push({name: 'trial.subscriptions'})
									}}
								>
									{this.$t('change_other_plan')}
								</div>
							</div>
							<div class='subs-package'>
								<div class='d-flex'>
									<Icon name='crown' size='18' class='mr-3 text__muted' style='position: relative; top: 3px;' />
									<div>
										<div class='text__bold'>{this.$t('package_unlimit_plan')}</div>
										<div>
											{this.$t('expiration')}: 12 {this.$t('month')}
										</div>
										<div>{this.$t('unlimit_agent_pack_desc', [100])}</div>
									</div>
								</div>
								<div class='text__semibold text__success text__xxl'>
									{sb.displayMoney(STANDARD_UNLIMITED_VND_PRICE)}
								</div>
							</div>
							<div class='mt-4 text__semibold'>{this.$t('payment_instruction')}</div>
							<div class='d-flex mt-2'>
								<div class='payment_modal_qr_card'>
									<div key='qr_loaded'>
										<img style='width: 100%;' src={this.getTransferQrCode()} />
										<div class='d-flex text__xs text__secondary text__muted mt-2'>
											<Icon name='scan' size='16' stroke-width='1.5' style='position: relative; top: 3px;' />
											<div class='ml-2'>{this.$t('scan_qr_purchase_desc')}</div>
										</div>
									</div>
								</div>
								<div class='payment_modal_bank_info_card'>
									<div class='text__sm text__muted mb-1'>{this.$t('bank_account_name')}</div>
									<div class='mt-1'>CÔNG TY CỔ PHẦN GIẢI PHÁP TRỰC TUYẾN VIETNAMBIZ</div>
									<div class='text__sm text__muted mt-3'>{this.$t('bank')}</div>
									<img src={require('./assets/img/vcb-logo.png')} width='80' />
									<div class='text__sm text__muted mb-1 mt-3'>{this.$t('bank_account')}</div>
									<div>
										{SUBIZ_BANK_ACCOUNT_ID}
										<Icon
											name='copy'
											size='18'
											class='ml-2 x-icon'
											vOn:click={() => {
												sb.copyToClipboard(SUBIZ_BANK_ACCOUNT_ID)
												this.$showSuccess(this.$t('copied'))
											}}
										/>
									</div>
									<div class='text__sm text__muted mb-1 mt-3'>{this.$t('payment_content')}</div>
									<div>
										{this.getContentTransfer()}
										<Icon
											name='copy'
											size='18'
											class='ml-2 x-icon'
											vOn:click={() => {
												sb.copyToClipboard(this.shortenData)
												this.$showSuccess(this.$t('copied'))
											}}
										/>
									</div>
								</div>
							</div>
							<div class='mt-2 text__sm'>
								<span class='text__muted'>
									{this.$t('contact_subiz_desc')}. {this.$t('if_you_have_any_question_please')}
								</span>{' '}
								<a href='javascript:;' vOn:click={() => this.$root.$emit('expandWidget')}>
									{this.$t('contact_with_subiz').toLowerCase()}
								</a>
							</div>
						</div>
					</div>
				)
			}

			return this.renderWarningModal()
		},

		renderWarningModal() {
			let acc = store.me().account
			let owner = store.matchAgent()[acc.owner_id]
			return (
				<div class='modal__background'>
					<div class='warning_modal_wrapper'>
						<div class='warning_modal_header d-flex'>
							<Icon name='alert-triangle-filled' size={100} />
						</div>
						<div class='warning_modal_content_wrapper'>
							<div class='warning_modal_content_title text__center'>{this.$t('your_account_will_be_deleted')}</div>
							<div class='warning_modal_content text__center'>
								{store.me().id != acc.owner_id
									? this.$t('churned_warning_for_agent', [
											lo.get(store.me(), 'account.name', this.$t('you')),
											lo.get(owner, 'email', 'support@subiz.com'),
										])
									: this.$t('churned_warning_for_owner', [lo.get(store.me(), 'account.name', this.$t('you'))])}
							</div>
						</div>
						<div class='d-flex mt-4' style={{flexDirection: 'column', alignItems: 'center'}}>
							{store.me().id == acc.owner_id && (
								<div
									class='btn btn__primary align-items-center flex__1 justify-content-center'
									style={{display: 'inline-flex', width: '370px', fontWeight: '700', textTranform: 'uppercase'}}
									vOn:click={() => {
										this.$router.push({name: 'trial.subscriptions'})
									}}
								>
									{this.$t('upgrade_plan')}
									<Icon name='arrow-narrow-right' size='18' stroke-width='2' class='ml-2' />
								</div>
							)}
							<div class='link link__secondary mt-3 mb-4' vOn:click={() => this.onCloseExpModal()}>
								{this.$t('upgrade_later')}
							</div>
						</div>
					</div>
				</div>
			)
		},
	},

	render() {
		return <Modal show={this.showExpModal}>{this.renderContent()}</Modal>
	},
}
