import sb from '@sb/util'
import store from '@sb/store'

import MessageTemplates from './messageTemplate.js'
import Suggestion from './suggestion.js'
import GenericTemplateBuilder from '../bot/generic_template_builder.js'

const LoadProductModal = () =>
	import(/*webpackChunkName: "product-modal" */ '../activities/conversation/productModal.js')

import ShadowDomRoot from '../activities/conversation/shadowDomRoot.js'
import AppendToBody from '../commons/append_to_body.js'
import {createEditor, $isTextNode} from 'lexical'
import {registerRichText, $isHeadingNode, HeadingNode, DRAG_DROP_PASTE} from '../lexical-test/lexical-rich-text.js'
import {registerPlainText} from '../lexical-test/lexical-plain-text.js'
import {
	LinkNode,
	AutoLinkNode,
	$createLinkNode,
	$isLinkNode,
	$isAutoLinkNode,
	TOGGLE_LINK_COMMAND,
	toggleLink,
} from '@lexical/link'
import {$generateNodesFromDOM, $generateHtmlFromNodes} from '@lexical/html'
import {$findMatchingParent, $getNearestBlockElementAncestorOrThrow, $getNearestNodeOfType} from '@lexical/utils'
import {registerEmojiTransformPlugin} from '../lexical-test/lexical-emoji-plugin.js'
import {
	ListNode,
	ListItemNode,
	INSERT_UNORDERED_LIST_COMMAND,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_CHECK_LIST_COMMAND,
	REMOVE_LIST_COMMAND,
	$isListNode,
} from '@lexical/list'
import {useList} from '../lexical-test/lexical-my-list.js'
import {registerAutoLinkPlugin, createLinkMatcherWithRegExp} from '../lexical-test/auto-link-plugins.js'
import {DynamicFieldNode, $isDynamicFieldNode, $createDynamicFieldNode} from '../lexical-test/lexical-dynamic-field.js'
import {
	$getSelection,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
	COMMAND_PRIORITY_CRITICAL,
	$getRoot,
	$createParagraphNode,
	$getNodeByKey,
	$isRootOrShadowRoot,
	$isParagraphNode,
	$isLineBreakNode,
	$insertNodes,
	createCommand,
	$setSelection,
	$createTextNode,
	$createLineBreakNode,
	COMMAND_PRIORITY_EDITOR,
	INDENT_CONTENT_COMMAND,
	FORMAT_ELEMENT_COMMAND,
	KEY_DOWN_COMMAND,
	OUTDENT_CONTENT_COMMAND,
	KEY_ENTER_COMMAND,
	CLEAR_EDITOR_COMMAND,
	$isRangeSelection,
	INSERT_LINE_BREAK_COMMAND,
	COMMAND_PRIORITY_LOW,
} from 'lexical'
import {$isAtNodeEnd} from '@lexical/selection'
import {ImageNode, $createImageNode, $isImageNode} from '../lexical-test/lexical-image-node.js'
import {MentionNode, $createMentionNode} from '../lexical-test/mention-node.js'
import {EmojiNode, $createEmojiNode, $isEmojiNode} from '../lexical-test/lexical-emoji-node.js'
import {registerHistory, createEmptyHistoryState} from '@lexical/history'
import {registerMarkdownShortcuts, ORDERED_LIST, UNORDERED_LIST} from '@lexical/markdown'
import {useClearEditorPlugin} from '../lexical-test/lexical-clear-editor-plugin.js'
import {$canShowPlaceholderCurry} from '@lexical/text'
import * as DOMPurify from 'dompurify'
import {isMimeType, mediaFileReader, $wrapNodeInElement, mergeRegister} from '@lexical/utils'
import Dropdown from './dropdown.js'
import LexicalMentionDropdown from './lexical-mentions-dropdown.js'
import lightGallery from 'lightgallery'

const INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND')

const config = {
	namespace: 'MyEditor',
	onError: console.error,
	theme: {
		text: {
			bold: 'sbz_lexical_text__bold',
			italic: 'sbz_lexical_text__italic',
			underline: 'sbz_lexical_text__underline',
		},
		paragraph: 'sbz_lexical_paragraph',
	},
	nodes: [LinkNode, AutoLinkNode, ListNode, ListItemNode, DynamicFieldNode, ImageNode, EmojiNode, HeadingNode],
}
const blockTypeToBlockName = {
	bullet: 'Bulleted List',
	check: 'Check List',
	code: 'Code Block',
	h1: 'Heading 1',
	h2: 'Heading 2',
	h3: 'Heading 3',
	h4: 'Heading 4',
	h5: 'Heading 5',
	h6: 'Heading 6',
	number: 'Numbered List',
	paragraph: 'Normal',
	quote: 'Quote',
}

// if you want add emoji selection, add more emoji class in ../assets/scss/_emoji.scss
// emoji code source was get hin ttps://emojipedia.org
const EMOJI_LIST = sb.LEXICAL_EMOJI_LIST

const PREVIEW_EMAIL_WIDTH = 800
// dynamicFields format
//
// DYNAMIC_FIELDS = {
//  fullname: {key: 'fullname', name: this.$t('user_name')},
// }
const ACCEPTABLE_IMAGE_TYPES = ['image/', 'image/heic', 'image/heif', 'image/gif', 'image/webp', 'image/png']
const IMG_MAX_WIDTH = 500

export default {
	name: 'lexical-editor',
	props: [
		'html',
		'markdown',
		'plaintext',
		'initMessage',
		'dynamicFields',
		'useTemplate',
		'useSuggestion',
		'useProduct',
		'useSingleImage',
		'useMention',
		'useAutoLink',
		'template_connector_type', // email, subiz, facebook, instagram, ...
		'placeholder',
		'has_submit_btn',
		'has_generic_templates',
		'submit_btn_loading',
		'no_preview_mode',
		'no_clear_on_submit',
		'cid',
		'uid',
		'transform_dynamic_field',
		'submit_on_enter',
		'has_like_button',
		'has_template_manage_btn',
		'no_show_attachments', // this props use for bot, campaign design, that attachments is display in seperate ways
		'autofocus',
		'fblinks',
	],
	data() {
		return {
			editor: null,

			mode: 'editor', // editor, source, preview
			isPreviewFullScreen: false,
			isPlaceholderShow: false,

			// toolbar state
			isBold: false,
			isItalic: false,
			isUnderline: false,
			blockType: 'paragraph',

			editingDynamicFieldNode: null,
			editingDynamicFieldDOM: null,

			// null is insert new Link node
			editingLinkNode: null,
			isEditLinkOpened: false,
			editingLinkHref: '',
			editingLinkText: '',
			editingLinkTextError: '',
			editingLinkHrefError: '',

			// image modal
			isInsertImageModalOpened: false,
			insertType: '',
			imgAltText: '',
			imgSrc: '',
			imgWidth: 0,
			uploading: false,

			// emoji dropdown
			isEmojiDropdownOpened: false,
			emojiDropdownStyle: {},

			// preview mode
			previewScale: 1,

			hasLexicalError: false,
			// use message template
			templateQuery: '',
			suggestionQuery: '',
			isMsgTemplateClick: false,

			// product modal
			show_product: false,
			is_dragging: false,
			is_uploading: false,

			// mentions dropdown
			isMentionsDropdownOpened: false,
			mentionQuery: '',

			// float text format popup
			isFloatTextFormatPopupOpened: false,
			floatTextFormatPopupStyle: {},
			//isEditLinkMode: false,
			isEditLinkFloatPopupOpened: false,
			floatLinkEditStyle: {},
			floatLinkHref: '',
		}
	},

	mounted() {
		this.galleryPlugins = {}

		this.initEditor()
		this.setInitialContent()
		this.updatePlaceholderState()

		this.$root.$on('conversationModalMessage', this.onConversationModalMessage)
		this.$root.$on('conversationModalMessage_send', this.onConversationModalMessageSend)
		this.$once('hook:beforeDestroy', () => {
			this.$root.$off('conversationModalMessage', this.onConversationModalMessage)
			this.$root.$off('conversationModalMessage_send', this.onConversationModalMessageSend)
		})

		document.addEventListener('selectionchange', this.updateFloatTextFormatPopup)
	},

	destroyed() {
		if (this.removeUpdateListener) this.removeUpdateListener()
		if (this.removeDynamicFieldMutationListener) this.removeDynamicFieldMutationListener()
		if (this.removeDragDropPasteCommand) this.removeDragDropPasteCommand()
		if (this.removeRegisterEnterMentionCommand) this.removeRegisterEnterMentionCommand()
		if (this.removeSubmitOnEnter) this.removeSubmitOnEnter()
		if (this.removeDecoratorListener) this.removeDecoratorListener()

		lo.each(this.galleryPlugins, (plugin) => {
			if (plugin && typeof plugin.destroy === 'function') {
				plugin.destroy()
			}
		})
		document.removeEventListener('selectionchange', this.updateFloatTextFormatPopup)
	},

	methods: {
		updateFloatEditLinkToolbar() {
			const selection = $getSelection()
			if ($isRangeSelection(selection)) {
				const focusNode = getSelectedNode(selection)
				const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode)
				const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode)
				if (!focusLinkNode && !focusAutoLinkNode) {
					this.isEditLinkFloatPopupOpened = false
					return
				}
				const badNode = selection
					.getNodes()
					.filter((node) => !$isLineBreakNode(node))
					.find((node) => {
						const linkNode = $findMatchingParent(node, $isLinkNode)
						const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode)
						return (
							(focusLinkNode && !focusLinkNode.is(linkNode)) ||
							(linkNode && !linkNode.is(focusLinkNode)) ||
							(focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
							(autoLinkNode && !autoLinkNode.is(focusAutoLinkNode))
						)
					})
				if (!badNode) {
					this.isEditLinkFloatPopupOpened = true
					this.focusLinkNode = focusLinkNode || focusAutoLinkNode
					this.floatLinkHref = this.focusLinkNode.getURL()
					this.calculateFloatLinkEditorStyle()
				} else {
					this.isEditLinkFloatPopupOpened = false
				}
			}
		},

		calculateFloatLinkEditorStyle() {
			if (!this.editor) return
			if (!this.html) return
			if (!this.isEditLinkFloatPopupOpened) return
			if (!this.focusLinkNode) return

			let key = this.focusLinkNode.getKey()
			let dom = this.editor.getElementByKey(key)
			let rect = dom.getBoundingClientRect()

			let {top = 0, left = 0, height = 0, right = 0} = rect
			const SPACER = 4
			const DROPDOWN_WIDTH = 280
			let style = {
				top: top + height + SPACER + 'px',
				height: 'auto',
				width: DROPDOWN_WIDTH + 'px',
				left: left - SPACER + 'px',
				bottom: 'unset',
			}
			let isRight = document.body.offsetWidth - (left + 50) < DROPDOWN_WIDTH
			if (isRight) {
				style.right = document.body.offsetWidth - left - DROPDOWN_WIDTH + 'px'
			} else {
				style.left = left + 'px'
			}
			this.floatLinkEditStyle = style
		},

		updateFloatTextFormatPopup() {
			if (!this.editor) return
			if (!this.html) return
			this.editor.getEditorState().read(() => {
				if (this.editor.isComposing()) return

				const selection = $getSelection()
				const nativeSelection = window.getSelection()
				const rootElement = this.editor.getRootElement()

				let selectionNotInEditor = !rootElement.contains(nativeSelection.anchorNode)
				selectionNotInEditor =
					selectionNotInEditor &&
					!(this.$refs.float_text_dropdown && this.$refs.float_text_dropdown.contains(nativeSelection.anchorNode))
				if (
					nativeSelection !== null &&
					(!$isRangeSelection(selection) || rootElement === null || selectionNotInEditor)
				) {
					this.isFloatTextFormatPopupOpened = false
					return
				}

				// dont recalculate if we focus on text link edit input
				if (this.isFocusFloatLInk) return

				const node = getSelectedNode(selection)
				if (selection.getTextContent() !== '') {
					this.isFloatTextFormatPopupOpened = $isTextNode(node) || $isParagraphNode(node)
					//if ($isLinkNode(node.getParent()) || $isAutoLinkNode(node.getParent())) {
					//this.isEditLinkMode = true
					//this.floatLinkHref = node.getParent().getURL()
					//this._currentNodeIsLink = true
					//} else {
					//this.isEditLinkMode = false
					//}
					if (this.isFloatTextFormatPopupOpened) {
						this.calculateFloatTextPopupStyle()
					}
				} else {
					this.isFloatTextFormatPopupOpened = false
				}
				const rawTextContent = selection.getTextContent().replace(/\n/g, '')
				if (selection.isCollapsed() || rawTextContent === '') {
					this.isFloatTextFormatPopupOpened = false
					return
				}
			})
		},

		calculateFloatTextPopupStyle() {
			if (!this.editor) return
			if (!this.html) return
			if (!this.isFloatTextFormatPopupOpened) return

			let selection = window.getSelection()
			let range = selection.getRangeAt(0)
			let rect = range.getBoundingClientRect()

			let {top = 0, left = 0, height = 0, right = 0} = rect
			const SPACER = 5
			const DROPDOWN_WIDTH = 200
			let style = {
				bottom: document.body.offsetHeight - top + SPACER + 'px',
				top: 'unset',
				//// user-select nont to dropdown so dont hide dropdown when click
				//userSelect: 'none',
			}
			let isRight = document.body.offsetWidth - (left + 50) < DROPDOWN_WIDTH
			if (isRight) {
				style.right = document.body.offsetWidth - left - DROPDOWN_WIDTH + 'px'
			} else {
				style.left = left + 'px'
			}
			this.floatTextFormatPopupStyle = style
		},

		onLinkInputKeydow(e) {
			if (e.key === 'Escape') {
				e.preventDefault()
				this.cancelEditFloatLink()
				return
			}
		},

		renderFloatEditLinkPopup() {
			if (!this.html) return
			if (!this.isEditLinkFloatPopupOpened) return
			return (
				<AppendToBody>
					<div
						class={{lexical_editor_float_text_format_popup: true, active: this.isEditLinkFloatPopupOpened}}
						style={this.floatLinkEditStyle || {}}
					>
						<div class='lexical_editor_float_text_format_popup_inner' style='padding: 10px' key='edit_link'>
							<form vOn:submit_prevent={this.submitFloatLinkEdit}>
								<input
									class='form-control form-control__light form-control-sm'
									ref='float_link_input'
									vOn:keydown={this.onLinkInputKeydow}
									vModel={this.floatLinkHref}
								/>
							</form>
							<div class='lexical_editor_float_text_format_popup_link_edit_actions'>
								<a style='margin-right: 8px; line-height: 1;' class='x-icon' href={this.floatLinkHref} target='_blank'>
									<Icon title={this.$t('visit_url')} name='external-link' size='16' />
								</a>
								<Icon
									vOn:click={this.submitFloatLinkEdit}
									title={this.$t('save')}
									name='check'
									style='margin-right: 8px'
									size='16'
									class='x-icon'
								/>
								<Icon
									title={this.$t('cancel')}
									name='x'
									size='16'
									class='x-icon'
									vOn:click={this.cancelEditFloatLink}
								/>
							</div>
						</div>
					</div>
				</AppendToBody>
			)
		},

		renderFloatTextFormatPopup() {
			if (!this.html) return
			if (!this.isFloatTextFormatPopupOpened) return
			//if (!this.isFloatTextFormatPopupOpened) return
			return (
				<AppendToBody>
					<div
						class={{lexical_editor_float_text_format_popup: true, active: this.isFloatTextFormatPopupOpened}}
						ref='float_text_dropdown'
						style={this.floatTextFormatPopupStyle || {}}
					>
						<div class='lexical_editor_float_text_format_popup_inner' key='format_text'>
							<div class='lexical_editor_float_popup_btn_group'>
								<div
									v-tooltip={this.$t('text_bold')}
									class={{lexical_editor_float_popup_btn: true, active: this.isBold}}
									vOn:click={this.toggleBold}
								>
									<Icon size='16' name='bold' />
								</div>
								<div
									v-tooltip={this.$t('text_italic')}
									class={{lexical_editor_float_popup_btn: true, active: this.isItalic}}
									vOn:click={this.toggleItalic}
								>
									<Icon size='16' name='italic' />
								</div>
								<div
									v-tooltip={this.$t('text_underline')}
									class={{lexical_editor_float_popup_btn: true, active: this.isUnderline}}
									vOn:click={this.toggleUnderline}
								>
									<Icon size='16' name='underline' />
								</div>
							</div>
							<div class='lexical_editor_float_popup_btn_group'>
								<div
									v-tooltip={this.$t('bullet_list')}
									class={{lexical_editor_float_popup_btn: true, active: this.blockType === 'bullet'}}
									vOn:click={this.formatBulletList}
								>
									<Icon size='16' name='list' />
								</div>
								<div
									class={{lexical_editor_float_popup_btn: true, active: this.blockType === 'number'}}
									vOn:click={this.formatNumberedList}
									v-tooltip={this.$t('numbered_list')}
								>
									<Icon size='16' name='list-numbers' />
								</div>
							</div>
							<div class='lexical_editor_float_popup_btn_group'>
								<div
									v-tooltip={this.$t('insert_link')}
									class='lexical_editor_float_popup_btn'
									vOn:click={(e) => this.insertLinkFromFloatPopup()}
								>
									<Icon size='16' name='link' />
								</div>
							</div>
						</div>
					</div>
				</AppendToBody>
			)
		},

		async insertLinkFromFloatPopup() {
			this.editor.update(() => {
				toggleLink('https://', {target: '_blank', rel: 'noopener noreferrer'})
				this.isFloatTextFormatPopupOpened = false
			})
		},

		submitFloatLinkEdit() {
			if (!this.editor) return
			if (!this.floatLinkHref) return
			this.editor.update(() => {
				toggleLink(this.floatLinkHref, {target: '_blank', rel: 'noopener noreferrer'})
				if ($isAutoLinkNode(this.focusLinkNode)) {
					let newLinkNode = $createLinkNode(this.focusLinkNode.getURL(), {
						rel: this.focusLinkNode.__rel,
						target: this.focusLinkNode.__target,
						title: this.focusLinkNode.__title,
					})
					this.focusLinkNode.replace(newLinkNode, true)
				}
				$setSelection(null)
			})
			this.isFloatTextFormatPopupOpened = false
			this.isEditLinkFloatPopupOpened = false
		},

		cancelEditFloatLink() {
			this.isEditLinkFloatPopupOpened = false
			//if (!this._currentNodeIsLink) {
			//this.isEditLinkMode = false
			//return
			//}
			//this.isFloatTextFormatPopupOpened = false
			//this.isEditLinkMode = false
			//if (!this.editor) return
			//this.editor.update(() => {
			//$setSelection(null)
			//})
		},

		onConversationModalMessage(message, viewid) {
			if (this.last_modal_view_id != viewid) return
			this.onSelectMessageTemplate(message)
			this.focus()
		},

		onConversationModalMessageSend(message, viewid) {
			if (this.last_modal_view_id != viewid) return
			this.$emit('submit', message)
		},

		emitChange(htmlString) {
			if (this._currentHtmlString === htmlString) return // dont need emit change when selection change
			let message = lo.cloneDeep(this.initMessage)
			message.text = htmlString
			this.$emit('change', message)
			this._currentHtmlString = htmlString
		},

		GetMessage() {
			return lo.cloneDeep(this.initMessage)
		},

		renderProductModal() {
			let ProductModal = lo.get(this, 'ProductModal.default')
			if (!ProductModal) return null
			return <ProductModal cid={this.cid} show={this.show_product} vOn:close={this.onCloseProductSelect} />
		},

		onCloseProductSelect() {
			this.show_product = false
		},

		renderFacebookDropdown() {
			if (!this.fblinks) return null
			let pages = lo
				.filter(store.matchIntegration(), (inte) => lo.get(inte, 'connector_type') === 'facebook')
				.filter((inte) => inte.state !== 'deleted')
			let items = lo.map(pages, (page) => ({
				id: page.id.split('.')[1],
				label: page.name,
				img: page.logo_url,
			}))

			return (
				<Dropdown
					items={items}
					class='lexical_editor_toolbar_btn'
					mode='custom'
					dropdown_width={200}
					vOn:select={(item) => this.onInsertFacebook(item)}
				>
					<Icon size='16' name='arrows-shuffle' v-tooltip={this.$t('send_Facebook_link')} />
				</Dropdown>
			)
		},

		async onInsertFacebook(page) {
			let convo = store.matchConvo(this.cid)
			let response = await store.getFacebookLinkRef({
				user_id: this.uid,
				conversation_id: this.cid,
				page_id: page.id,
			})
			if (response.error) {
				this.$showError(response.error)
				return
			}

			let locale = lo.get(convo, 'locale', 'vi-VN')
			let btnTitle = 'Đi đến Messenger'
			let text = 'Nhấn vào nút phía bên dưới để liên hệ với chúng tôi qua Facebook/Messenger'
			if (locale !== 'vi-VN') {
				text = 'Click the button below to contact us via Facebook/Messenger'
				btnTitle = 'Go to Messenger'
			}

			let url = response.body
			let msg = {
				text,
				format: 'plaintext',
				attachments: [
					{
						type: 'button',
						buttons: [
							{
								type: 'url_button',
								title: btnTitle,
								url: url,
								payload: JSON.stringify({action: 'fb_link_ref'}),
							},
						],
					},
				],
			}

			if (!this.editor) return
			this.editor.update(() => {
				let root = $getRoot()
				let firstNode = findFirstTextNode(root)
				let insertText = $createTextNode(text)
				if (firstNode) {
					const linebreak = $createLineBreakNode()
					$insertNodes([linebreak, insertText])
				} else {
					$insertNodes([insertText])
				}
				let message = lo.cloneDeep(this.initMessage)
				message.attachments = msg.attachments
				this.$emit('change', message)
			})
			// store.sendMessage2({touchpoint: touchpoint, msg: msg, convoid: this.cid, userids: [this.user_id]})
		},

		focus() {
			if (!this.editor) return
			this.editor.focus()
		},

		Focus() {
			return this.focus()
		},

		blur() {
			if (!this.editor) return
			this.editor.blur()
		},

		async setInitialContent() {
			// use local id to prevent uploaded filed state when clear message
			this._local_id = sb.randomString(12) + Date.now()

			// set default is '<p></p>' to avoid paste text cause erorr append root node only decorator or element node
			let htmlString = lo.get(this.initMessage, 'text', '<p></p>')
			this.sourceCodeHtml = htmlString
			if (htmlString === undefined) return
			// try setEditable = false so editor is not autofocus when update initital value
			this.editor.setEditable(false)
			let res = await this.setHtmlContent(htmlString)
			this.editor.setEditable(true)
			if (this.autofocus && this.editor) this.editor.focus()
			if (lo.get(res, 'error')) {
				if (this.no_preview_mode) return
				this.sourceCodeHtml = htmlString
				this.mode = 'source'
				this.emitChange(htmlString)
			}
		},

		applySuggestion(action) {
			if (action.confirm) {
				this.setHtmlContent(`<p><span>${action.text}, hit Enter to process...</span></p>`)
				// must wait for on-text-change flow to end, before setting confirm action
				setTimeout(() => {
					this.confirmAction = action
				}, 10)
				return
			}

			this.editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
			this.$emit('action', action)
		},

		async onSelectMessageTemplate(msg) {
			if (this.html) {
				let delta = msg.quill_delta
				delta = sb.parseJSON(msg.quill_delta)
				if (delta && !msg.is_template) {
					let deltaHTMLString = await sb.deltaToLexicalHtml(delta)
					if (this.transform_dynamic_field) {
						deltaHTMLString = this.transformDynamicField(deltaHTMLString)
					}
					let res1 = await this.setHtmlContent(deltaHTMLString)
					if (!lo.get(res1, 'error')) {
						await this.$nextTick()
						let newMessage = lo.cloneDeep(this.initMessage)
						newMessage.attachments = lo.cloneDeep(msg.attachments)
						newMessage.fields = lo.cloneDeep(msg.fields)
						this.$emit('change', newMessage)
						this.$emit('selectTemplate', newMessage)
						return
					}
				}
				let htmlString = msg.text
				if (this.transform_dynamic_field) {
					htmlString = this.transformDynamicField(htmlString)
				}
				let res = await this.setHtmlContent(htmlString)
				if (lo.get(res, 'error')) {
					if (this.no_preview_mode) return this.$showError('invalid_msg_template_format')
					this.sourceCodeHtml = htmlString
					this.mode = 'source'
					this.emitChange(htmlString)
				}

				await this.$nextTick()
				let newMessage = lo.cloneDeep(this.initMessage)
				newMessage.attachments = lo.cloneDeep(msg.attachments)
				newMessage.fields = lo.cloneDeep(msg.fields)
				this.$emit('change', newMessage)
				this.$emit('selectTemplate', newMessage)
			}
			if (this.plaintext) {
				let htmlString = sb.plainTextToLexicalHtml(msg.text)
				if (msg.is_template) {
					htmlString = msg.text
				} else {
					let delta = msg.quill_delta
					if (delta) {
						delta = sb.parseJSON(delta) || {}
						htmlString = await sb.deltaToLexicalHtml(delta)
					} else {
						htmlString = sb.plainTextToLexicalHtml(msg.text)
					}
				}
				if (this.transform_dynamic_field) {
					htmlString = this.transformDynamicField(htmlString)
				}
				let res = await this.setHtmlContent(htmlString)
				this.emitChange(htmlString)

				await this.$nextTick()
				let newMessage = lo.cloneDeep(this.initMessage)
				newMessage.attachments = msg.attachments
				this.$emit('change', newMessage)
				this.$emit('selectTemplate', newMessage)
			}
		},

		transformDynamicField(htmlString) {
			let div = document.createElement('div')
			div.innerHTML = htmlString
			let $spans = div.querySelectorAll('[data-dynamic-field]')
			let user = store.matchUser(this.uid) || {}
			lo.each($spans, ($span) => {
				let field = $span.getAttribute('data-dynamic-field')
				let text = ''
				if (field === 'user.fullname') {
					text = sb.getUserTextAttr(user, 'fullname') || sb.getUserTextAttr(user, 'display_name')
				}
				$span.textContent = text
				$span.setAttribute('class', '')
				$span.removeAttribute('data-dynamic-field')
			})

			let result = div.innerHTML
			div.remove()
			return result
		},

		openMessageTemplate() {
			if (!this.useTemplate) return
			if (!this.editor) return
			this.editor.getEditorState().read(() => {
				let root = $getRoot()
				let firstNode = findFirstTextNode(root)
				if (firstNode) {
					this.templateQuery = firstNode.getTextContent()
				} else {
					this.templateQuery = ''
				}
			})
		},

		openSuggestion() {
			if (!this.editor) return
			if (!this.useSuggestion) return
			this.editor.getEditorState().read(() => {
				let root = $getRoot()
				let firstNode = findFirstTextNode(root)
				if (firstNode) {
					this.suggestionQuery = firstNode.getTextContent()
				} else {
					this.suggestionQuery = ''
				}
			})
		},

		setHtmlContent(htmlString) {
			if (!this.editor) return {}
			this.hasLexicalError = false
			return new Promise((resolve, reject) => {
				this.editor.update(() => {
					if (!htmlString) resolve()
					try {
						// In the browser you can use the native DOMParser API to parse the HTML string.
						const parser = new DOMParser()
						const dom = parser.parseFromString(htmlString, 'text/html')

						// Once you have the DOM instance it's easy to generate LexicalNodes.
						const nodes = $generateNodesFromDOM(this.editor, dom)

						let root = $getRoot()
						let selection = $getSelection()
						let paragraph = $createParagraphNode()

						// remove all children from node
						root.clear()

						$insertNodes(nodes)
						resolve()
					} catch (err) {
						console.error('parseHTMLErorrrr', err)
						resolve({error: 'parse_html_error'})
					}
				})
			})
		},

		initEditor() {
			this.config = lo.cloneDeep(config)
			if (!this.html) {
				this.config.nodes = [EmojiNode, DynamicFieldNode]
			}
			if (this.useMention) {
				this.config.nodes.push(MentionNode)
			}
			if (this.useAutoLink) {
				if (!lo.includes(this.config.nodes, AutoLinkNode)) {
					this.config.nodes.push(AutoLinkNode)
				}
			}
			const editor = createEditor(this.config)
			editor.setRootElement(this.$refs.editor)

			// logic copy and drag drop files
			this.removeDragDropPasteCommand = editor.registerCommand(
				DRAG_DROP_PASTE,
				(files) => {
					console.log('DRAG_DROP_PASTEEEEEEE', files)
					if (!this.html) {
						this.addLocalFiles({target: {files}})
					} else {
						;(async () => {
							const filesResult = await mediaFileReader(
								files,
								[ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x),
							)
							for (const {file, result} of filesResult) {
								if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
									let base64 = result
									base64 = lo.split(base64, ',')
									let contentType = base64[0] || ''
									contentType = contentType.replace(';base64', '')
									contentType = contentType.replace('data:', '')
									let b64Data = base64[1]
									let {width, height} = await sb.getImageDemensionFromUrl(result)
									let imgWidth = 0
									let imgHeight = 0
									if (width <= IMG_MAX_WIDTH) {
										imgWidth = width
										imgHeight = height
									} else {
										imgWidth = IMG_MAX_WIDTH
										imgHeight = (IMG_MAX_WIDTH * height) / width
										imgHeight = Math.round(imgHeight)
									}
									editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
										altText: file.name,
										src: sb.b64toBlobUrl(b64Data, contentType),
										width: imgWidth + 'px',
										height: imgHeight + 'px',
									})
								}
							}

							let textFiles = lo.filter(files, (file) => file.type.startsWith('text/'))
							for (let tFile of textFiles) {
								let reader = new FileReader()
								reader.onload = (e) => {
									this.editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false)
									this.editor.update(() => {
										let result = $createTextNode(reader.result)
										let p = $createParagraphNode()
										p.append(result)
										$insertNodes([p])
									})
								}
								reader.readAsText(tFile)
							}
						})()
					}
					return true // stop propagation
				},
				COMMAND_PRIORITY_LOW,
			)

			if (this.plaintext) {
				registerPlainText(editor)
			} else {
				registerRichText(editor)
			}
			registerHistory(editor, createEmptyHistoryState())
			registerEmojiTransformPlugin(editor)
			useClearEditorPlugin(editor)
			if (this.html) {
				registerMarkdownShortcuts(editor, [ORDERED_LIST, UNORDERED_LIST])
				useList(editor)
				editor.registerCommand(
					INSERT_IMAGE_COMMAND,
					(payload) => {
						const imageNode = $createImageNode(payload)
						// we must listen INSERT_IMAGE_COMMAND to replace src from blob to true URL
						// Cannot use in registerMutationListener because it causes loop
						// Be careful this event was published to all LexicalEditor components

						let selection = $getSelection()
						const anchorNode = selection.anchor.getNode()
						if ($isRootOrShadowRoot(anchorNode)) {
							$insertNodes([$createParagraphNode(), imageNode, $createParagraphNode()])
						} else if ($isParagraphNode(anchorNode)) {
							if (anchorNode.getPreviousSibling()) {
								$insertNodes([imageNode, $createParagraphNode()])
							} else {
								// this mean cursor is in the first paragraph, need a space above image
								$insertNodes([$createParagraphNode(), imageNode, $createParagraphNode()])
							}
						} else {
							if ($isTextNode(anchorNode) && anchorNode.getTextContent()) {
								// insert image in new line if already has text
								let p = $createParagraphNode()
								$insertNodes([p])
								p.select()
								$insertNodes([imageNode, $createParagraphNode()])
							} else {
								$insertNodes([imageNode, $createParagraphNode()])
							}
						}
						//$insertNodes([$createParagraphNode(),imageNode, $createParagraphNode()])

						// dont need thses logic because we always insert paragraph before and after image
						//if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
						//$wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
						//}

						this.tryUploadNewImageNode(imageNode)
						return true
					},
					COMMAND_PRIORITY_EDITOR,
				)
			}
			if (this.useAutoLink) {
				const URL_REGEX =
					/((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

				const MATCHERS = [
					createLinkMatcherWithRegExp(URL_REGEX, (text) => {
						return text.startsWith('http') ? text : `https://${text}`
					}),
				]
				registerAutoLinkPlugin(editor, MATCHERS)
			}

			if (this.submit_on_enter) {
				this.removeSubmitOnEnter = editor.registerCommand(
					KEY_ENTER_COMMAND,
					(ev) => {
						if (ev.shiftKey) return false
						if (this.confirmAction) {
							editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
							this.$emit('action', this.confirmAction)
							this.confirmAction = null
							return false
						}
						if (this.useTemplate && this.$refs.message_template && this.$refs.message_template.IsShow()) return false
						if (this.useSuggestion && this.$refs.suggestion && this.$refs.suggestion.IsShow()) return false
						if (this.useMention && this.isMentionsDropdownOpened) return false

						this.onSubmit()
						//this.$emit('submit')
						//this._local_id = sb.randomString(12) + Date.now()
						//editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
						//return true // stopPropagation
					},
					COMMAND_PRIORITY_CRITICAL,
				)
			}
			if (this.useMention) {
				this.removeRegisterEnterMentionCommand = editor.registerCommand(
					KEY_ENTER_COMMAND,
					(e) => {
						if (!this.isMentionsDropdownOpened) {
							e.preventDefault()
							return false
						}
						// we need to override defaylt add break line if mentiosn commend is open
						e.preventDefault()
						return true
					},
					COMMAND_PRIORITY_CRITICAL,
				)
			}

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					//this.editor = newEditor
					this.updateToolbarState()
					this.openMessageTemplate()
					this.openSuggestion()
					this.updateFloatEditLinkToolbar()
					return false
				},
				COMMAND_PRIORITY_CRITICAL,
			)

			if (this.useMention) {
				this.removeMentionKeyPressRegister = editor.registerCommand(
					KEY_DOWN_COMMAND,
					() => {
						this._allowOpenMentions = true
						return false
					},
					COMMAND_PRIORITY_LOW,
				)
			}

			this.removeUpdateListener = editor.registerUpdateListener(({editorState}) => {
				// The latest EditorState can be found as `editorState`.
				// To read the contents of the EditorState, use the following API:

				editorState.read(() => {
					if (this.confirmAction) {
						this.confirmAction = null
						editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
					}
					let htmlString = $generateHtmlFromNodes(editor)
					this.updateToolbarState()
					this.updatePlaceholderState()
					this.updateMentionsState()
					this.emitChange(htmlString)
				})
			})
			if (this.html) {
				this.imageNodeKeys = [] // this variable is maintain all image nodes of editor. User for lightgallery
				this.removeImageMutationListener = editor.registerMutationListener(ImageNode, (mutatedNodes) => {
					const registeredElements = new WeakSet()
					editor.getEditorState().read(() => {
						for (const [key, mutation] of mutatedNodes) {
							const element = editor.getElementByKey(key)
							const node = $getNodeByKey(key)
							console.log('ImageNode mutation log', key, mutation)
							if (mutation === 'created') {
								if (!lo.includes(this.imageNodeKeys, key)) this.imageNodeKeys.push(key)
							} else if (mutation === 'destroyed') {
								this.imageNodeKeys = lo.filter(this.imageNodeKeys, (ckey) => ckey !== key)
							}
							if (
								// Updated might be a move, so that might mean a new DOM element
								// is created. In this case, we need to add and event listener too.
								(mutation === 'created' || mutation === 'updated') &&
								element !== null &&
								!registeredElements.has(element)
							) {
								registeredElements.add(element)
								element.addEventListener('click', (e) => {
									this.onClickImageNode(e, node)
								})
							}
						}
					})
					console.log('ImageNode mutation doneee', this.imageNodeKeys)
				})
				this.removeDecoratorListener = editor.registerDecoratorListener((decorators) => {
					lo.each(decorators, (decorator, nodeKey) => {
						let element = this.editor.getElementByKey(nodeKey)
						element.innerHTML = ''
						element.append(decorator)
					})
				})
			}
			// use registerMutationListener invoke addEventListener eachtime node setTextContent, so onClickEVent will be called multi times. Later try to removeEventListner
			this.removeDynamicFieldMutationListener = editor.registerMutationListener(DynamicFieldNode, (mutatedNodes) => {
				const registeredElements = new WeakSet()
				editor.getEditorState().read(() => {
					for (const [key, mutation] of mutatedNodes) {
						const element = editor.getElementByKey(key)
						const node = $getNodeByKey(key)
						if (
							// Updated might be a move, so that might mean a new DOM element
							// is created. In this case, we need to add and event listener too.
							(mutation === 'created' || mutation === 'updated') &&
							element !== null &&
							!registeredElements.has(element)
						) {
							registeredElements.add(element)
							element.addEventListener('click', (e) => {
								this.onClickDynamicField(e, node)
							})
						}
					}
				})
			})

			if (this.autofocus) editor.focus()
			this.editor = editor
		},

		async tryUploadNewImageNode(imageNode) {
			let key = imageNode.getKey()
			// work around because somehow the event was fired before imageNode was inserted
			await sb.sleep(100)
			let element = this.editor.getElementByKey(key)
			if (!element) return

			let $img = element.querySelector('img')
			let src = $img.src
			if (!src) return

			let isBlob = (src || '').startsWith('blob:')
			if (!isBlob) return

			element.classList.add('sbz_lexical_img_wrapper_loading')
			let file = await sb.blobUrlToFile(src)
			let res = await store.uploadLocalFile(file)
			element.classList.remove('sbz_lexical_img_wrapper_loading')

			if (res.url) {
				this.editor.update(() => {
					let node = $getNodeByKey(key)
					if (node && $isImageNode(node)) {
						node.setSrc(res.url)
					}
				})
			}
		},

		onClickImageNode(e, node) {
			if (this.gallery && typeof this.gallery.destroy === 'function') this.gallery.destroy()
			let nodeKey = node.getKey()
			let imgIdx = lo.findIndex(this.imageNodeKeys, (key) => key === nodeKey)
			// must use this HTML markup to use lightgallery
			let div = document.createElement('div')
			lo.each(this.imageNodeKeys, (key) => {
				let element = this.editor.getElementByKey(key)
				let src = element.querySelector('img').src
				let a = document.createElement('a')
				let img = document.createElement('img')
				img.setAttribute('src', src)
				a.setAttribute('href', src)
				a.appendChild(img)
				div.appendChild(a)
			})
			this.gallery = lightGallery(div)
			this.gallery.openGallery(imgIdx)
		},

		updatePlaceholderState() {
			if (!this.editor) return

			const currentCanShowPlaceholder = this.editor
				.getEditorState()
				.read($canShowPlaceholderCurry(this.editor.isComposing()))
			this.isPlaceholderShow = currentCanShowPlaceholder
		},

		async onClickDynamicField(e, node) {
			// must use stopPropagation to avoid retoggle dropdown
			e.stopPropagation()
			this.editingDynamicFieldNode = node
			this.editingDynamicFieldDOM = e.target
			await this.$nextTick()
			this.$refs.custom_token_dropdown.ToogleDropdown()
		},

		updateToolbarState() {
			if (!this.editor) return
			const selection = $getSelection()
			if (!$isRangeSelection(selection)) return

			this.isBold = selection.hasFormat('bold')
			this.isItalic = selection.hasFormat('italic')
			this.isUnderline = selection.hasFormat('underline')

			// check block type is list or paragraph
			const anchorNode = selection.anchor.getNode()
			let element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: $findMatchingParent(anchorNode, (e) => {
							const parent = e.getParent()
							return parent !== null && $isRootOrShadowRoot(parent)
					  })

			if (element === null) {
				element = anchorNode.getTopLevelElementOrThrow()
			}
			const elementKey = element.getKey()
			const elementDOM = this.editor.getElementByKey(elementKey)

			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode)
					const type = parentList ? parentList.getListType() : element.getListType()
					this.blockType = type
				} else {
					this.blockType = 'paragraph'
				}
			}
		},

		toggleBold() {
			this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
		},
		toggleItalic() {
			this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
		},
		toggleUnderline() {
			this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
		},

		changeInsertType(type) {
			this.insertType = type
			this.imgSrc = ''
			this.imgAltText = ''
			this.imgWidth = 0
			this.uploading = false
		},

		async detechImageWidth() {
			try {
				let {width, height} = await sb.getImageDemensionFromUrl(this.imgSrc)
				this.imgWidth = width <= IMG_MAX_WIDTH ? width : IMG_MAX_WIDTH
			} catch (err) {
				console.log('detechImageWidth errrr', err)
			}
		},

		renderInsertImageModal() {
			if (!this.html) return null

			let $content = null
			if (!this.insertType) {
				$content = (
					<div class='d-flex justify-content-center' style='height: 100%;'>
						<div style='width: 240px; padding-top: 60px;'>
							<div class='text__muted text__center'>{this.$t('add_image_from')}</div>
							<div class='btn w_100  btn__light mt-4' vOn:click={() => this.changeInsertType('url')}>
								{'URL'}
							</div>
							<div type='button' class='btn w_100 mt-4 btn__light' vOn:click={() => this.changeInsertType('file')}>
								{this.$t('your_computer')}
							</div>
						</div>
					</div>
				)
			}
			if (this.insertType === 'url') {
				$content = (
					<div class='d-flex flex-column' style='height: 100%;'>
						<div class='mb-1'>{'URL'}</div>
						<input class='form-control' vModel={this.imgSrc} vOn:change={this.detechImageWidth} />
						<div class='mb-1 mt-4'>{this.$t('alt_text')}</div>
						<input class='form-control' vModel={this.imgAltText} />
						<div class='mb-1 mt-4'>{this.$t('preview')}</div>
						{this.imgSrc && <img src={this.imgSrc} style='max-height: 100px; width: 120px' />}
						<div class='mt-auto d-flex align-items-center justify-content-end'>
							<button class='btn btn__sm btn__light mr-4' vOn:click={() => this.changeInsertType('')}>
								{this.$t('back')}
							</button>
							<button class='btn btn__sm btn__primary' vOn:click={this.submitInsertImage}>
								{this.$t('confirm')}
							</button>
						</div>
					</div>
				)
			}
			if (this.insertType === 'file') {
				$content = (
					<div class='d-flex flex-column' style='height: 100%;'>
						<input
							type='file'
							ref='insert_image_input'
							accept='image/*'
							style='display: none;'
							vOn:change={this.uploadSingleImage}
						/>
						<div class='mb-1'>{this.$t('choose_file')}</div>
						<div class='d-flex align-items-center'>
							<button
								class='btn btn__white align-items-center'
								style='display: inline-flex; width: fit-content'
								vOn:click={() => this.$refs.insert_image_input.click()}
							>
								<Icon name='upload' size='16' class='mr-2' />
								{this.$t('upload')}
							</button>
							{this.uploading && <div class='ml-3'>{this.$t('uploading')}...</div>}
						</div>
						<div class='mb-1 mt-4'>{this.$t('alt_text')}</div>
						<input class='form-control' vModel={this.imgAltText} />
						<div class='mb-1 mt-4'>{this.$t('preview')}</div>
						{this.imgSrc && <img src={this.imgSrc} style='max-height: 100px; width: 120px' />}
						<div class='mt-auto d-flex align-items-center justify-content-end'>
							<button class='btn btn__sm btn__light mr-4' vOn:click={() => this.changeInsertType('')}>
								{this.$t('back')}
							</button>
							<button class='btn btn__sm btn__primary' vOn:click={this.submitInsertImage}>
								{this.$t('confirm')}
							</button>
						</div>
					</div>
				)
			}

			return (
				<Modal show={this.isInsertImageModalOpened} vOn:bg={this.closeInsertImageModal}>
					<div class='modal__background'>
						<div class='modal_content' style='width: 480px'>
							<div class='modal_content__header d-flex align-items-center'>
								{this.$t('insert_image')}
								<Icon name='x' class='ml-auto x-icon' size='24' vOn:click={this.closeInsertImageModal} />
							</div>
							<div class='modal_content__main' style='height: 420px'>
								{$content}
							</div>
						</div>
					</div>
				</Modal>
			)
		},

		submitInsertImage() {
			if (!this.editor) return
			this.editor.update(() => {
				let selection = $getSelection()
				let props = {
					src: this.imgSrc,
					altText: this.imgAltText,
				}
				if (this.imgWidth) {
					props.width = this.imgWidth + 'px'
				}
				let imageNode = $createImageNode(props)
				if (selection) {
					const anchorNode = selection.anchor.getNode()
					if (anchorNode.getKey() === 'root') {
						const p = $createParagraphNode()
						$insertNodes([p, imageNode])
					} else {
						$insertNodes([imageNode])
					}
				} else {
					const p = $createParagraphNode()
					$insertNodes([p, imageNode])
				}
			})
			this.closeInsertImageModal()
		},

		closeInsertImageModal() {
			this.isInsertImageModalOpened = false
		},

		openInsertImageModal() {
			this.isInsertImageModalOpened = true
			this.insertType = ''
			this.imgAltText = ''
			this.imgSrc = ''
		},

		selectFormatText(format) {
			if (!this.editor) return
			if (format === 'indent') {
				this.editor.dispatchCommand(INDENT_CONTENT_COMMAND)
				return
			}
			if (format === 'outdent') {
				this.editor.dispatchCommand(OUTDENT_CONTENT_COMMAND)
			}
			this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, format)
		},

		formatBulletList() {
			if (this.blockType !== 'bullet') {
				this.editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
			} else {
				this.editor.dispatchCommand(REMOVE_LIST_COMMAND)
			}
		},

		formatNumberedList() {
			if (this.blockType !== 'number') {
				this.editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
			} else {
				this.editor.dispatchCommand(REMOVE_LIST_COMMAND)
			}
		},

		onInsertDynamicField(item) {
			let attrKey = item.id
			if (!this.editor) return
			this.editor.update(() => {
				let selection = $getSelection()
				const dynamicFieldNode = $createDynamicFieldNode(item.label, `user.${attrKey}`)
				if (selection) {
					const anchorNode = selection.anchor.getNode()
					if (anchorNode.getKey() === 'root') {
						const p = $createParagraphNode()
						$insertNodes([p, dynamicFieldNode])
					} else {
						$insertNodes([dynamicFieldNode])
					}
				} else {
					const p = $createParagraphNode()
					$insertNodes([p, dynamicFieldNode])
				}
			})
		},

		submitEditDynamicField(item) {
			let attrKey = item.id
			if (!this.editor) return
			if (!this.editingDynamicFieldNode) return
			this.editor.update(() => {
				this.editingDynamicFieldNode.setTextContent(item.label)
				this.editingDynamicFieldNode.setAttrKey(`user.${attrKey}`)
			})

			// make sure delete editingDynamicFieldNode to re triggrt onClickDynamicField event
			this.editingDynamicFieldNode = null
			this.editingDynamicFieldDOM = null
		},

		selectLinkNode(node, dom) {
			return
			const selection = window.getSelection()

			if (selection.rangeCount > 0) {
				selection.removeAllRanges()
			}

			const range = document.createRange()
			range.selectNode(dom)
			selection.addRange(range)
		},

		async openEditLink(node, dom) {
			//console.log('openEditLink', node, node.getKey(), dom)
			//const selection = window.getSelection()
			////const strongs = document.getElementsByTagName('strong')

			//if (selection.rangeCount > 0) {
			//selection.removeAllRanges()
			//}

			//const range = document.createRange()
			//range.selectNode(dom)
			//selection.addRange(range)

			//return
			//test
			this.editingLinkNode = node
			this.isEditLinkOpened = true
			this.editingLinkHref = ''
			this.editingLinkText = ''
			this.editor.getEditorState().read(() => {
				if (node) {
					this.editingLinkHref = node.getURL() || ''
					let textNode = node.getChildren()
					textNode = lo.get(textNode, 0)
					this.editingLinkText = textNode ? textNode.getTextContent() : ''
				} else {
					let selection = $getSelection()
					if (!selection) return
					let nodes = selection.getNodes()

					// only allow edit text node
					if (lo.size(nodes) === 1 && $isTextNode(nodes[0])) {
						this.editingLinkText = selection.getTextContent()
					}
				}
			})

			await this.$nextTick()
			this.$refs.edit_link_href_input && this.$refs.edit_link_href_input.focus()
		},

		closeEditLink() {
			this.editingLinkNode = null
			this.isEditLinkOpened = false
		},

		// use for test, remove later
		clickSubmitEditLink() {
			this.submitEditLink()
		},

		submitEditLink() {
			if (!this.editor) return
			this.resetValidateEditForm()
			if (!this.validateEditLinkForm()) return

			let isNewLink = !this.editingLinkNode
			if (isNewLink) {
				this.editor.update(() => {
					const linkNode = $createLinkNode(this.editingLinkHref, {target: '_blank', rel: 'noopener noreferrer'})
					let linkTextNode = $createTextNode(this.editingLinkText)

					let selection = $getSelection()
					if (selection) {
						const anchorNode = selection.anchor.getNode()
						if (anchorNode.getKey() === 'root') {
							const p = $createParagraphNode()
							linkNode.append(linkTextNode)
							$insertNodes([p, linkNode])
						} else {
							$insertNodes([linkTextNode])
							// dont try append TextNode directly inside AutoLinkNode cause text after LinkNode to be link. Use toggleLink instead
							linkTextNode.select()
							toggleLink(this.editingLinkHref, {target: '_blank', rel: 'noopener noreferrer'})
						}
					} else {
						const p = $createParagraphNode()
						linkNode.append(linkTextNode)
						$insertNodes([p, linkNode])
					}
				})
			} else {
				this.editor.update(() => {
					this.editingLinkNode.setURL(this.editingLinkHref)
					let textNode = this.editingLinkNode.getChildren()
					textNode = lo.get(textNode, 0)
					if (textNode) textNode.setTextContent(this.editingLinkText)
					this.editingLinkNode.select()
				})
			}
			this.closeEditLink()
		},

		resetValidateEditForm() {
			this.editingLinkTextError = ''
			this.editingLinkHrefError = ''
		},

		validateEditLinkForm() {
			if (!lo.trim(this.editingLinkText)) {
				this.editingLinkTextError = this.$t('cannot_be_empty')
			}
			if (!sb.validateUrl(this.editingLinkHref)) {
				this.editingLinkHrefError = this.$t('invalid_url')
			}
			if (this.editingLinkHrefError || this.editingLinkTextError) {
				return false
			}
			return true
		},

		renderEditLinkModal() {
			let isNewLink = !this.editingLinkNode

			return (
				<Modal show={this.isEditLinkOpened} vOn:bg={this.closeEditLink}>
					<div class='modal__background'>
						<div class='modal_content' style='width: 480px'>
							<div class='modal_content__header d-flex align-items-center'>
								{isNewLink ? this.$t('insert_link') : this.$t('edit_link')}
								<Icon name='x' class='ml-auto x-icon' size='24' vOn:click={this.closeEditLink} />
							</div>
							<div class='modal_content__main' style='padding: 15px 20px'>
								<form id='edit_link_form' vOn:submit_prevent={this.submitEditLink}>
									<div class='mb-1 d-flex align-items-center'>{this.$t('link_href')}</div>
									<div class='form-control-group'>
										<input
											ref='edit_link_href_input'
											class={{'form-control': true, 'is-invalid': this.editingLinkHrefError}}
											vModel={this.editingLinkHref}
										/>
										<a
											href={this.editingLinkHref}
											target='_blank'
											style='display: inline-flex'
											class='btn btn__light align-items-center justify-content-center no-shrink'
										>
											<Icon name='external-link' size='16' class='mr-2' />
											{this.$t('visit_url')}
										</a>
									</div>
									{this.editingLinkHrefError && (
										<div class='text__danger text__xs mt-1'>{this.editingLinkHrefError}</div>
									)}
									<div class='mb-1 mt-4'>{this.$t('link_text')}</div>
									<input
										ref='edit_link_text_input'
										class={{'form-control': true, 'is-invalid': this.editingLinkTextError}}
										vModel={this.editingLinkText}
									/>
									{this.editingLinkTextError && (
										<div class='text__danger text__xs mt-1'>{this.editingLinkTextError}</div>
									)}
									<div class='mt-5 d-flex align-items-center justify-content-end'>
										<button type='button' class='btn btn__sm btn__white mr-4' vOn:click={this.closeEditLink}>
											{this.$t('close')}
										</button>
										<button
											type='submit'
											form='edit_link_form'
											class='btn btn__sm btn__primary'
											vOn:click={this.clickSubmitEditLink}
										>
											{this.$t('confirm')}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</Modal>
			)
		},

		renderEditDynamicFieldDropdown() {
			if (!this.editingDynamicFieldDOM) return null
			let rect = this.editingDynamicFieldDOM.getBoundingClientRect() || {}
			const dynamicFieldItems = lo.map(this.dynamicFields, (item) => ({
				id: item.key,
				label: item.name,
			}))

			let style = `z-index: 10;background: transparent;width: ${rect.width}px; height: ${rect.height}px; position: fixed; top:${rect.top}px;left:${rect.left}px; cursor: pointer;`
			return (
				<Dropdown2
					ref='custom_token_dropdown'
					style={style}
					mode='custom'
					items={dynamicFieldItems}
					dropdown_width={180}
					vOn:select={this.submitEditDynamicField}
				>
					<div ref='custom_token' style={style} />
				</Dropdown2>
			)
		},

		renderEmojiSelectDropdown() {
			let style = lo.cloneDeep(this.emojiDropdownStyle)
			if (!this.isEmojiDropdownOpened) return null //style.display = 'none'

			return (
				<AppendToBody style={style}>
					<div class='dropdown d-flex flex-wrap'>
						{EMOJI_LIST.map((emoji) => {
							return (
								<div
									class='emoji_picker__item d-flex align-items-center justify-content-center'
									vOn:click={() => this.testInsertEmoji(emoji)}
								>
									<Emoji emojiCode={emoji.text} md />
								</div>
							)
						})}
					</div>
				</AppendToBody>
			)
		},

		toggleEmojiDropdown() {
			this.isEmojiDropdownOpened = !this.isEmojiDropdownOpened
			this.calculateEmojiDropdownStyle()
		},

		calculateEmojiDropdownStyle() {
			let $trigger = this.$refs.emoji_trigger
			const DROPDOWN_WIDTH = 270
			const DROPDOWN_HEIGHT = 170
			const EDGE = 30

			let rect = $trigger ? $trigger.getBoundingClientRect() : {}
			let {width = 0, height = 0, left = 0, top = 0} = rect
			let style = {
				position: 'absolute',
				zIndex: 9999,
				top: top + height + 'px',
				left: left + 'px',
				width: DROPDOWN_WIDTH + 'px',
				height: DROPDOWN_HEIGHT + 'px',
				padding: '10px',
			}
			let isTop = top + height + DROPDOWN_HEIGHT + EDGE >= window.innerHeight
			let isRight = width + left + DROPDOWN_WIDTH + EDGE >= window.innerWidth
			if (isTop) {
				style.top = 'unset'
				style.bottom = window.innerHeight - top + 'px'
			}
			if (isRight) {
				style.left = 'unset'
				style.right = window.innerWidth - (left + width) + 'px'
			}
			this.emojiDropdownStyle = style
		},

		testInsertEmoji(emoji) {
			if (!this.editor) return
			this.editor.update(() => {
				let selection = $getSelection()
				let emojiNode = $createEmojiNode(`lexical-emoji ${emoji.code}`, emoji.text)
				if (selection) {
					const anchorNode = selection.anchor.getNode()
					if (anchorNode.getKey() === 'root') {
						const p = $createParagraphNode()
						$insertNodes([p, emojiNode])
					} else {
						$insertNodes([emojiNode])
					}
				} else {
					const p = $createParagraphNode()
					$insertNodes([p, emojiNode])
				}
			})
			this.closeEmojiDropdown()
		},

		closeEmojiDropdown() {
			this.isEmojiDropdownOpened = false
		},

		onSubmit() {
			let isZalo = false
			const ZALO_ACCEPTTED_MIMETYPES = [
				'application/msword',
				'application/pdf',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			]
			if (this.cid) {
				let convo = store.matchConvo(this.cid)
				isZalo = lo.get(convo, 'touchpoint.channel') === 'zalo'
			} else {
				let user = store.matchUser(this.uid, true)
				isZalo = lo.get(user, 'channel') === 'zalo'
			}
			let attachments = lo.get(this.initMessage, 'attachments', [])
			if (
				isZalo &&
				lo.find(
					attachments,
					(file) =>
						!ZALO_ACCEPTTED_MIMETYPES.includes(file.mimetype) &&
						!(file.mimetype || '').startsWith('image') &&
						file.type === 'file',
				)
			) {
				this.$showError(this.$t('zalo_only_support_format_files'))
				return
			}

			this.$emit('submit')
			this._local_id = sb.randomString(12) + Date.now()
			if (!this.no_clear_on_submit) this.editor.dispatchCommand(CLEAR_EDITOR_COMMAND)
		},

		productSelectClick() {
			this.isPromoteProduct = false
			store.updatePromoteProduct(false)
			this.$emit('productSelected')
			this.onProductSelected()
		},

		async onProductSelected() {
			this.show_product = !this.show_product
			if (!this.ProductModal) {
				this.ProductModal = await LoadProductModal()
				this.$forceUpdate()
			}
		},

		// trigger change for same file
		clearFileInput(e) {
			e.target.value = ''
		},

		async hitLikeButton() {
			let emoji = lo.find(EMOJI_LIST, (emoji) => emoji.text === '👍')
			this.testInsertEmoji(emoji)
			await this.$nextTick()
			this.onSubmit()
		},

		async sendRequestInfo() {
			let convo = store.matchConvo(this.cid)
			if (!convo) return
			let oa_id = lo.get(convo, 'touchpoint.source')
			let user_id = lo.get(convo, 'touchpoint.id')
			let res = await store.sendZaloRequestInfo({oa_id, user_id})
			if (res.error) {
				let code = lo.get(res, 'body.code') || ''
				let error = 'Bạn đã gửi yêu cầu với người dùng này'
				if (code.indexOf('invalid_integration') > -1) {
					error = 'OA không khả dụng'
				}
				this.$showError(error)
			}
		},

		useGallery() {
			let att = lo.get(this.initMessage, 'attachments.0') || {}
			if (att.type === 'generic') return
			let message = lo.cloneDeep(this.initMessage)
			let locale = lo.get(store.me(), 'account.locale')
			locale = locale.replace('-', '_')

			message.attachments = [
				{
					type: 'generic',
					elements: [
						{
							title: 'Sản phẩm 1',
							i18n_title: {[locale]: 'Sản phẩm 1'},
							image_url: 'https://vcdn.subiz-cdn.com/file/fiqxarzhhfhtkixrqlug-5.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
						{
							title: 'Sản phẩm 2',
							i18n_title: {[locale]: 'Sản phẩm 2'},
							image_url: 'https://vcdn.subiz-cdn.com/file/fiqxarymkkbsyykitvju-2.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
					],
				},
			]
			this.$emit('change', message)
		},

		renderToolbar() {
			const textAlignItems = [
				{
					id: 'left',
					label: this.$t('align_left'),
					icon: <Icon name='align-left' size='18' />,
				},
				{
					id: 'right',
					label: this.$t('align_right'),
					icon: <Icon name='align-right' size='18' />,
				},
				{
					id: 'center',
					label: this.$t('align_center'),
					icon: <Icon name='align-center' size='18' />,
				},
				{
					id: 'justify',
					label: this.$t('align_justify'),
					icon: <Icon name='align-justified' size='18' />,
				},
				{
					seperator: true,
				},
				{
					id: 'indent',
					label: this.$t('indent'),
					icon: <Icon name='indent-increase' size='18' />,
				},
				{
					id: 'outdent',
					label: this.$t('outdent'),
					icon: <Icon name='indent-decrease' size='18' />,
				},
			]

			let $dynamicField = null
			if (lo.size(this.dynamicFields)) {
				let dynamicFieldItems = lo.map(this.dynamicFields, (item) => ({
					id: item.key,
					label: item.name,
				}))
				$dynamicField = (
					<Dropdown
						vOn:select={(item) => this.onInsertDynamicField(item)}
						mode='custom'
						class='lexical_editor_toolbar_btn_wrapper'
						right
						dropdown_width={180}
						items={dynamicFieldItems}
					>
						<div class='lexical_editor_toolbar_btn' v-tooltip={this.$t('insert_variable')}>
							<Icon size='16' name='braces' />
						</div>
					</Dropdown>
				)
			}

			let $content = null
			let attachments = lo.get(this.initMessage, 'attachments', [])
			let disabled = lo.find(attachments, (att) => att._loading) || this.submit_btn_loading
			if (this.html) {
				$content = (
					<Fragment>
						<div class='lexical_editor_toolbar_btn_group'>
							<div
								v-tooltip={this.$t('text_bold')}
								class={{lexical_editor_toolbar_btn: true, active: this.isBold}}
								vOn:click={this.toggleBold}
							>
								<Icon size='16' name='bold' />
							</div>
							<div
								v-tooltip={this.$t('text_italic')}
								class={{lexical_editor_toolbar_btn: true, active: this.isItalic}}
								vOn:click={this.toggleItalic}
							>
								<Icon size='16' name='italic' />
							</div>
							<div
								v-tooltip={this.$t('text_underline')}
								class={{lexical_editor_toolbar_btn: true, active: this.isUnderline}}
								vOn:click={this.toggleUnderline}
							>
								<Icon size='16' name='underline' />
							</div>
						</div>
						<div class='lexical_editor_toolbar_btn_group'>
							<Dropdown
								mode='custom'
								style='max-height: 400px'
								no_filter
								vOn:select={(item) => this.selectFormatText(item.id)}
								class='lexical_editor_toolbar_btn_wrapper'
								items={textAlignItems}
								dropdown_width={180}
							>
								<div v-tooltip={this.$t('text_align')} class='lexical_editor_toolbar_btn' ref='toolbar_btn_align'>
									<Icon size='16' name='align-left' />
									<Icon size='14' name='chevron-down' class='ml-1 text__muted' />
								</div>
							</Dropdown>
						</div>
						<div class='lexical_editor_toolbar_btn_group'>
							<div
								v-tooltip={this.$t('bullet_list')}
								class={{lexical_editor_toolbar_btn: true, active: this.blockType === 'bullet'}}
								vOn:click={this.formatBulletList}
							>
								<Icon size='16' name='list' />
							</div>
							<div
								class={{lexical_editor_toolbar_btn: true, active: this.blockType === 'number'}}
								vOn:click={this.formatNumberedList}
								v-tooltip={this.$t('numbered_list')}
							>
								<Icon size='16' name='list-numbers' />
							</div>
						</div>
						<div class='lexical_editor_toolbar_btn_group'>
							<div
								v-tooltip={this.$t('insert_link')}
								class='lexical_editor_toolbar_btn'
								vOn:click={(e) => this.openEditLink()}
							>
								<Icon size='16' name='link' />
							</div>
							<div
								class='lexical_editor_toolbar_btn'
								v-tooltip={this.$t('insert_image')}
								vOn:click={this.openInsertImageModal}
							>
								<Icon size='16' name='photo' />
							</div>
							<div
								class='lexical_editor_toolbar_btn'
								v-tooltip={this.$t('add_attachments')}
								vOn:click={() => this.$refs.file_input.click()}
							>
								<Icon size='16' name='paperclip' />
							</div>
							<input
								type='file'
								ref='file_input'
								multiple
								style='display: none;'
								vOn:click={this.clearFileInput}
								vOn:change={this.addLocalFiles}
							/>
							<div class='lexical_editor_toolbar_btn_wrapper' v-clickaway={this.closeEmojiDropdown}>
								<div
									ref='emoji_trigger'
									class={{lexical_editor_toolbar_btn: true, active: this.isEmojiDropdownOpened}}
									v-tooltip={this.$t('Emoji')}
									vOn:click={this.toggleEmojiDropdown}
								>
									<Icon size='16' name='mood-smile' />
								</div>
								{this.renderEmojiSelectDropdown()}
							</div>
							{$dynamicField}
						</div>
						{this.has_submit_btn && (
							<div class='lexical_editor_toolbar_btn_group'>
								<button
									type='button'
									disabled={disabled}
									class='btn btn__sm btn__primary align-items-center'
									style='display: inline-flex'
									vOn:click={this.onSubmit}
								>
									<Icon size='16' name='send' class='mr-2' />
									<div style='line-height: 1'>{this.$t('submit')}</div>
								</button>
							</div>
						)}
					</Fragment>
				)
			}

			if (this.markdown) {
				$content = (
					<Fragment>
						<div class='lexical_editor_toolbar_btn_group'>
							<div
								v-tooltip={this.$t('text_bold')}
								class={{lexical_editor_toolbar_btn: true, active: this.isBold}}
								vOn:click={this.toggleBold}
							>
								<Icon size='16' name='bold' />
							</div>
							<div
								v-tooltip={this.$t('text_italic')}
								class={{lexical_editor_toolbar_btn: true, active: this.isItalic}}
								vOn:click={this.toggleItalic}
							>
								<Icon size='16' name='italic' />
							</div>
							<div
								v-tooltip={this.$t('text_underline')}
								class={{lexical_editor_toolbar_btn: true, active: this.isUnderline}}
								vOn:click={this.toggleUnderline}
							>
								<Icon size='16' name='underline' />
							</div>
						</div>
						<div class='lexical_editor_toolbar_btn_group'>
							<div class='lexical_editor_toolbar_btn_wrapper' v-clickaway={this.closeEmojiDropdown}>
								<div
									ref='emoji_trigger'
									class={{lexical_editor_toolbar_btn: true, active: this.isEmojiDropdownOpened}}
									v-tooltip={this.$t('Emoji')}
									vOn:click={this.toggleEmojiDropdown}
								>
									<Icon size='16' name='mood-smile' />
								</div>
								{this.renderEmojiSelectDropdown()}
							</div>
							{$dynamicField}
						</div>
					</Fragment>
				)
			}

			if (this.plaintext) {
				let $facebook = this.renderFacebookDropdown()
				let $zalo = null
				let convo = store.matchConvo(this.cid) || {}
				let channel = lo.get(convo, 'touchpoint.channel')

				if (channel === 'zalo') {
					let user = store.matchUser(this.uid, true) || {}
					let isFollowed = sb.getUserBooleanAttr(user, 'is_followed')

					let style = 'padding: 2px 8px;'
					if (!isFollowed) {
						style += 'opacity: 0.6; cursor: initial'
					}
					$zalo = (
						<div
							class='btn btn__sm btn__light mr-2'
							style={style}
							v-tooltip={!isFollowed ? this.$t('only_available_for_oa_followed_user') : ''}
							vOn:click={() => isFollowed && this.sendRequestInfo()}
						>
							{this.$t('request_info')}
						</div>
					)
				}

				let $product = null
				if (this.useProduct) {
					$product = (
						<div
							class='lexical_editor_toolbar_btn'
							v-tooltip={this.$t('select_product')}
							vOn:click_stop={this.productSelectClick}
						>
							<Icon size='16' name='shopping-cart' />
						</div>
					)
				}

				let $like = null
				if (this.has_like_button) {
					let text = lo.get(this.initMessage, 'text')
					text = sb.lexicalToPlainText(text)
					let attachments = lo.get(this.initMessage, 'attachments')

					$like = (
						<div class='lexical_editor_toolbar_btn' vOn:click={this.hitLikeButton} key='toolbar_like'>
							<Icon size='18' name='thumb-up-filled' class='text__primary' />
						</div>
					)
					if (text || lo.size(attachments)) {
						$like = (
							<div
								class='lexical_editor_toolbar_btn'
								vOn:click={this.onSubmit}
								v-tooltip={this.$t('submit')}
								key='toolbar_send'
							>
								<Icon size='18' name='send' class='text__primary icon-filled' />
							</div>
						)
					}
				}

				let $templateBtn = null
				if (this.has_template_manage_btn) {
					$templateBtn = (
						<div
							class='lexical_editor_toolbar_btn'
							vOn:click_stop={this.onMgsTemplateClicked}
							v-tooltip={this.$t('title_message_template')}
						>
							<Icon size='16' name='message-bolt' />
						</div>
					)
				}

				let $gallery = null
				if (this.has_generic_templates) {
					$gallery = (
						<div class='lexical_editor_toolbar_btn' v-tooltip={this.$t('gallery')} vOn:click={() => this.useGallery()}>
							<Icon size='16' name='columns-2' />
						</div>
					)
				}

				let $addAttachment = (
					<Fragment>
						<div
							class='lexical_editor_toolbar_btn'
							v-tooltip={this.$t('add_attachments')}
							vOn:click={() => this.$refs.file_input.click()}
						>
							<Icon size='16' name='paperclip' />
						</div>
						<input
							type='file'
							vOn:click={this.clearFileInput}
							ref='file_input'
							multiple
							style='display: none;'
							vOn:change={this.addLocalFiles}
						/>
					</Fragment>
				)
				let $singleImage = null

				if (this.useSingleImage) {
					$addAttachment = null
					$singleImage = (
						<Fragment>
							<div
								class='lexical_editor_toolbar_btn'
								v-tooltip={this.$t('add_image')}
								vOn:click={() => this.$refs.single_image_input.click()}
							>
								<Icon size='16' name='photo' />
							</div>
							<input
								type='file'
								vOn:click={this.clearFileInput}
								ref='single_image_input'
								accept='image/*'
								style='display: none;'
								vOn:change={this.uploadSingleImage}
							/>
						</Fragment>
					)
				}

				$content = (
					<Fragment>
						<div class='lexical_editor_toolbar_btn_group'>
							{$zalo}
							{$facebook}
							{$product}
							{$addAttachment}
							<div class='lexical_editor_toolbar_btn_wrapper' v-clickaway={this.closeEmojiDropdown}>
								<div
									ref='emoji_trigger'
									class={{lexical_editor_toolbar_btn: true, active: this.isEmojiDropdownOpened}}
									v-tooltip={this.$t('Emoji')}
									vOn:click={this.toggleEmojiDropdown}
								>
									<Icon size='16' name='mood-smile' />
								</div>
								{this.renderEmojiSelectDropdown()}
							</div>
							{$dynamicField}
							{$singleImage}
							{$gallery}
							{$templateBtn}
							{$like}
						</div>
						{this.has_submit_btn && (
							<div class='lexical_editor_toolbar_btn_group'>
								<button
									type='button'
									disabled={disabled}
									class='btn btn__sm btn__primary align-items-center'
									style='display: inline-flex'
									vOn:click={this.onSubmit}
								>
									<Icon size='16' name='send' class='mr-2' />
									<div style='line-height: 1'>{this.$t('submit')}</div>
								</button>
							</div>
						)}
					</Fragment>
				)
			}

			return (
				<div class='lexical_editor_toolbar'>
					<div class='flex__1' style='overflow: hidden' vOn:click={() => this.editor && this.editor.focus()}>
						{this.renderAuthor()}
					</div>
					{$content}
				</div>
			)
		},

		onMgsTemplateClicked() {
			// show modal create message template if account doesnt have any msg template
			if (!lo.size(store.matchMessageTemplate())) {
				this.$root.$emit('message_template_modal_open')
				return
			}

			if (this.$refs.message_template && this.$refs.message_template.IsShow()) {
				this.$refs.message_template.Show(false)
				this.editor.focus()
				return
			}

			this.$refs.message_template.Show(true, '/')
			this.editor.focus()
		},

		renderAuthor() {
			let convo = store.matchConvo(this.cid)
			if (!this.cid || !convo) return null
			let channel = lo.get(convo, 'touchpoint.channel', 'subiz')
			if (channel === 'subiz') return null
			if (channel === 'email') return null
			if (channel === 'account') return null
			if (channel === 'call') return null
			if (channel === 'google_message')
				return (
					<div class='message_editor__author'>
						<img
							class='convo_header__source_img'
							style='width: 14px; height: 14px; border-radius: 3px'
							src={require('./../assets/img/google_message_channel.svg')}
						/>
						<a v-tooltip={this.$t('message_will_be_sent_using_this_page')} class='ml-2 text__muted text__sm'>
							{lo.get(convo, 'integration.name', 'subiz')}
						</a>
					</div>
				)
			if (channel === 'google_question')
				return (
					<div class='message_editor__author'>
						<img
							class='convo_header__source_img'
							style='width: 14px; height: 14px; border-radius: 3px'
							src={require('./../assets/img/google_question_channel.svg')}
						/>
						<a v-tooltip={this.$t('message_will_be_sent_using_this_page')} class='ml-2 text__muted text__sm'>
							{lo.get(convo, 'integration.name', 'subiz')}
						</a>
					</div>
				)
			//if (lo.get(convo, 'touchpoint.channel', 'subiz') == 'subiz') return null
			if (this.html) return null

			let inteid = sb.getIntegrationIdFromConvo(convo)
			let inte = store.matchIntegration()[inteid] || {}

			return (
				<div class='message_editor__author'>
					<img
						class='convo_header__source_img'
						style='width: 14px; height: 14px; border-radius: 3px'
						src={inte.logo_url}
					/>
					<a v-tooltip={this.$t('message_will_be_sent_using_this_page')} class='ml-2 text__muted text__sm'>
						{inte.name}
					</a>
				</div>
			)
		},

		async addLocalFiles(e, type) {
			let localId = this._local_id

			let message = lo.cloneDeep(this.initMessage)
			let efiles = lo.get(e, 'target.files', [])
			let files = message.attachments || []
			let newFiles = []
			for (let i = 0; i < efiles.length; i++) {
				let file = efiles[i]
				let id = sb.randomString(20)
				let tempUrl = await sb.getBlobUrlFromFile(file)
				newFiles.push({
					type: 'file',
					mimetype: file.type,
					url: tempUrl,
					size: file.size,
					name: file.name,
					_loading: true,
					_local_id: id,
					_file: file,
				})
			}
			message.attachments = [...files, ...newFiles]
			if (localId !== this._local_id) return
			this.$emit('change', message)

			await flow.map(newFiles, 5, async (file) => {
				let res = await store.uploadLocalFile(file._file)
				let attachmentIndex = lo.findIndex(message.attachments, (cFile) => cFile._local_id === file._local_id)
				if (res.error) {
					if (attachmentIndex > -1) lo.set(message, `attachments.${attachmentIndex}._error`, res.error)
				} else {
					if (attachmentIndex > -1) {
						let mimetype = file.mimetype
						if ((file.name || '').endsWith('heic') || (file.name || '').endsWith('heif')) {
							mimetype = res.type
						}
						lo.set(message, `attachments.${attachmentIndex}`, {
							type: 'file',
							mimetype: mimetype,
							url: res.url,
							size: file.size,
							name: file.name,
						})
					}
				}
			})

			let error = ''
			lo.each(message.attachments, (file) => {
				if (file._error) {
					error = file._error
					return false // break
				}
			})

			if (error) this.$showError(this.$t(error))

			message.attachments = lo.filter(message.attachments, (file) => !file._error)
			message = lo.cloneDeep(message)
			this.editor && this.editor.focus()
			if (localId !== this._local_id) return
			this.$emit('change', message)
		},

		async uploadSingleImage(e, type) {
			let localId = this._local_id

			let message = lo.cloneDeep(this.initMessage)
			let efiles = lo.get(e, 'target.files', [])
			let files = message.attachments || []
			let newFiles = []

			for (let i = 0; i < 1; i++) {
				let file = efiles[i]
				let id = sb.randomString(20)
				let tempUrl = await sb.getBlobUrlFromFile(file)
				newFiles.push({
					type: 'file',
					mimetype: file.type,
					url: tempUrl,
					size: file.size,
					name: file.name,
					_loading: true,
					_local_id: id,
					_file: file,
				})
			}
			message.attachments = newFiles
			if (localId !== this._local_id) return
			this.$emit('change', message)

			await flow.map(newFiles, 5, async (file) => {
				let res = await store.uploadLocalFile(file._file)
				let attachmentIndex = lo.findIndex(message.attachments, (cFile) => cFile._local_id === file._local_id)
				if (res.error) {
					if (attachmentIndex > -1) lo.set(message, `attachments.${attachmentIndex}._error`, res.error)
				} else {
					if (attachmentIndex > -1) {
						let mimetype = file.mimetype
						if ((file.name || '').endsWith('heic') || (file.name || '').endsWith('heif')) {
							mimetype = res.type
						}
						lo.set(message, `attachments.${attachmentIndex}`, {
							type: 'file',
							mimetype: mimetype,
							url: res.url,
							size: file.size,
							name: file.name,
						})
					}
				}
			})

			let error = ''
			lo.each(message.attachments, (file) => {
				if (file._error) {
					error = file._error
					return false // break
				}
			})

			if (error) this.$showError(this.$t(error))

			message.attachments = lo.filter(message.attachments, (file) => !file._error)
			message = lo.cloneDeep(message)
			this.editor && this.editor.focus()
			if (localId !== this._local_id) return
			this.$emit('change', message)
		},

		onRemoveAttachment(idx) {
			let message = lo.cloneDeep(this.initMessage)
			let attachments = message.attachments || []
			attachments.splice(idx, 1)
			message.attachments = attachments
			this.$emit('change', message)
		},

		renderFiles() {
			if (this.no_show_attachments) return null
			let files = lo.get(this.initMessage, 'attachments', [])
			files = lo.filter(files, (file) => file.type === 'file' || file.type === 'button')
			if (!lo.size(files)) return null

			let isZalo = false
			const ZALO_ACCEPTTED_MIMETYPES = [
				'application/msword',
				'application/pdf',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			]
			if (this.cid) {
				let convo = store.matchConvo(this.cid)
				isZalo = lo.get(convo, 'touchpoint.channel') === 'zalo'
			} else {
				let user = store.matchUser(this.uid, true)
				isZalo = lo.get(user, 'channel') === 'zalo'
			}
			return (
				<div class='lexical_editor_attachments'>
					{lo.map(files, (file, idx) => {
						if ((file.mimetype || '').startsWith('image')) {
							return (
								<div class='task_detail_attachment'>
									<img2
										clickable
										src={file.url}
										style='width: 100%; height: 100%; border-radius: 8px; overflow: hidden'
									/>

									{file._loading && (
										<div
											style='z-index: 2; background: rgba(255, 255, 255, 0.7); position: absolute; inset: 0;'
											class='d-flex align-items-center justify-content-center'
										>
											<Spinner mode='blue' />
										</div>
									)}
									{!file._loading && (
										<div class='task_detail_attachment_x' vOn:click={() => this.onRemoveAttachment(idx)}>
											<Icon name='x' size='12' stroke-width='2' />
										</div>
									)}
								</div>
							)
						}
						// display error noti with non pdf, doc, docx in zalo convo
						let zaloError = isZalo && !ZALO_ACCEPTTED_MIMETYPES.includes(file.mimetype) && file.type === 'file'

						return (
							<div class={{task_detail_attachment: true, error: zaloError}}>
								{file.type === 'button' ? (
									<div class='text__center text__sm pl-3 pr-3' style='max-width: 100%'>
										<Icon name='external-link' size='16' stroke-width='2' class='text__secondary' />
										<br />
										<a
											style='display: inline-block; overflow: hidden; max-height: 30px; max-width: 100%; text-align: center;'
											class='link link__secondary text__xxs mt-2'
										>
											Đi đến Messenger
										</a>
									</div>
								) : (
									<div class='text__center text__sm pl-3 pr-3' style='max-width: 100%'>
										<Icon
											name='file'
											size='16'
											stroke-width='2'
											v-tooltip={zaloError && this.$t('zalo_only_support_format_files')}
											class={{text__secondary: !zaloError, text__danger: zaloError}}
										/>
										<br />
										<a
											href={file.url}
											target='_blank'
											style='display: inline-block; overflow: hidden; max-height: 30px; max-width: 100%'
											class={{
												link: true,
												text__xxs: true,
												'mt-2': true,
												link__secondary: !zaloError,
												link__danger: zaloError,
											}}
											title={file.name}
										>
											{file.name}
										</a>
									</div>
								)}
								{file._loading && (
									<div
										style='z-index: 2; background: rgba(255, 255, 255, 0.7); position: absolute; inset: 0;'
										class='d-flex align-items-center justify-content-center'
									>
										<Spinner mode='blue' />
									</div>
								)}
								{!file._loading && (
									<div class='task_detail_attachment_x' vOn:click={() => this.onRemoveAttachment(idx)}>
										<Icon name='x' size='12' stroke-width='2' />
									</div>
								)}
							</div>
						)
					})}
				</div>
			)
		},

		async toggleMode(mode) {
			if (this.mode === mode) return
			this.mode = mode
			if (mode === 'source') {
				this.sourceCodeHtml = lo.get(this.initMessage, 'text')
			}
			if (mode === 'editor') {
				this.sourceCodeHtml = lo.get(this.initMessage, 'text')
				let currentSourceCodeHtml = this.sourceCodeHtml
				let res = await this.setHtmlContent(this.sourceCodeHtml)
				if (lo.get(res, 'error')) {
					this.hasLexicalError = true
					this.sourceCodeHtml = currentSourceCodeHtml
					this.emitChange(currentSourceCodeHtml)
				}
			}
			if (mode === 'preview') {
				this.calculateScale()
			}
		},

		calculateScale() {
			const $wrapper = this.$refs.wrapper
			if (!$wrapper) return
			let {width} = $wrapper.getBoundingClientRect()
			let scale = width / PREVIEW_EMAIL_WIDTH
			scale = Math.ceil(scale * 100) / 100 // round to second decimal
			this.scale = scale
		},

		renderPreviewBlock() {
			if (this.mode !== 'preview') return
			if (this.no_preview_mode) return
			let htmlString = lo.get(this.initMessage, 'text')
			htmlString = DOMPurify.sanitize(htmlString, {ALLOW_UNKNOWN_PROTOCOLS: true})

			if (this.has_submit_btn) {
				let attachments = lo.get(this.initMessage, 'attachments', [])
				let disabled = lo.find(attachments, (att) => att._loading)
				return (
					<Fragment>
						<div class='lexical_editor_preview_block d-flex flex-column'>
							<div
								class='lexical_editor_preview_block_full_screen_btn'
								vOn:click={() => (this.isPreviewFullScreen = true)}
							>
								<Icon name='arrows-maximize' size='16' />
							</div>
							<div class='lexical_editor_preview_block_innner flex__1'>
								<ShadowDomRoot html={htmlString} scale={this.scale} throttle_update />
							</div>
							<div class='lexical_editor_toolbar'>
								<div class='lexical_editor_toolbar_btn_group'>
									<button
										type='button'
										class='btn btn__sm btn__primary align-items-center'
										disabled={disabled}
										style='display: inline-flex'
										vOn:click={this.onSubmit}
									>
										<Icon size='16' name='send' class='mr-2' />
										<div style='line-height: 1'>{this.$t('submit')}</div>
									</button>
								</div>
							</div>
						</div>
						{this.renderPreviewFullScreen(htmlString)}
					</Fragment>
				)
			}
			return (
				<Fragment>
					<div class='lexical_editor_preview_block'>
						<div
							class='lexical_editor_preview_block_full_screen_btn'
							vOn:click={() => (this.isPreviewFullScreen = true)}
						>
							<Icon name='arrows-maximize' size='16' />
						</div>
						<div class='lexical_editor_preview_block_innner'>
							<ShadowDomRoot html={htmlString} scale={this.scale} throttle_update />
						</div>
					</div>
					{this.renderPreviewFullScreen(htmlString)}
				</Fragment>
			)
		},

		renderPreviewFullScreen(htmlString) {
			let cls = 'lexical_editor_preview_full_screen'
			if (!this.isPreviewFullScreen) return null
			return (
				<div class={cls}>
					<Icon
						name='x'
						class='link link__white'
						style='position: absolute; top: 30px; right: 30px'
						size='32'
						vOn:click={() => (this.isPreviewFullScreen = false)}
					/>
					<div style='width: 1024px; overflow: hidden; background-color: white; border-radius: 8px'>
						<div style='max-height: 768px; overflow-y: auto'>
							<ShadowDomRoot html={htmlString} />
						</div>
					</div>
				</div>
			)
		},

		renderErrorOverlay() {
			if (this.no_preview_mode) return null
			if (!this.hasLexicalError) return null
			if (this.mode != 'editor') return null

			return (
				<div class='lexical_editor_error_overlay'>
					<div class='text__white text__center'>{this.$t('lexial_wrong_format_warning')}</div>
					<button onClick={(_) => (this.hasLexicalError = false)} class='btn btn__primary mt-3'>
						{this.$t('edit')}
					</button>
				</div>
			)
		},

		renderPlaceholder() {
			let hidden = !this.placeholder || !this.isPlaceholderShow

			return <div class={{lexical_editor_placeholder: true, hidden}}>{this.placeholder}</div>
		},

		renderSourceEditor() {
			if (this.mode !== 'source') return
			if (this.no_preview_mode) return
			if (!this.has_submit_btn) {
				return (
					<textarea
						class='form-control'
						style='position: absolute; inset: 0; z-index: 999; font-size: 12px; border: none; outline: none; resize: none;'
						vModel={this.sourceCodeHtml}
						vOn:change={() => this.emitChange(this.sourceCodeHtml)}
					/>
				)
			}

			let attachments = lo.get(this.initMessage, 'attachments', [])
			let disabled = lo.find(attachments, (att) => att._loading)

			return (
				<div style='position: absolute; inset: 0; z-index: 999; background-color: #fff' class='d-flex flex-column'>
					<textarea
						class='form-control flex__1'
						style='font-size: 12px; border: none; outline: none; resize: none;'
						vModel={this.sourceCodeHtml}
						vOn:change={() => this.emitChange(this.sourceCodeHtml)}
					/>
					<div class='lexical_editor_toolbar'>
						<div class='lexical_editor_toolbar_btn_group'>
							<button
								type='button'
								disabled={disabled}
								class='btn btn__sm btn__primary align-items-center'
								style='display: inline-flex'
								vOn:click={this.onSubmit}
							>
								{this.submit_btn_loading ? (
									<Spinner size='16' class='mr-2' />
								) : (
									<Icon size='16' name='send' class='mr-2' />
								)}
								<div style='line-height: 1'>{this.$t('submit')}</div>
							</button>
						</div>
					</div>
				</div>
			)
		},

		renderGenericTemplates() {
			if (this.no_show_attachments) return null
			let attachments = lo.get(this.initMessage, 'attachments', [])
			let att = lo.find(attachments, (attachment) => attachment.type === 'generic')
			if (!att) return null
			return (
				<GenericTemplateBuilder
					noBranching
					locale={lo.get(store.me(), 'account.locale')}
					style='width: 100%; padding-right: 10px; padding-left:10px; padding-bottom: 10px'
					message={this.initMessage}
					vOn:change={(msg) => this.$emit('change', msg)}
					vOn:remove={this.removeGallery}
				/>
			)
		},

		removeGallery() {
			let message = lo.cloneDeep(this.initMessage)
			let attachments = lo.get(message, 'attachments', [])
			attachments = lo.filter(attachments, (att) => att.type !== 'generic')
			message.attachments = attachments
			this.$emit('change', message)
		},

		async onModal(e) {
			this.onSelectMessageTemplate({text: ''})
			let out = await store.pickConversationModal(this.cid, e.id, e.query, this.uid)
			if (out.error || !lo.get(out, 'body.url')) return this.$showError(this.$t('open_modal_failed'))
			let convo = store.matchConvo(this.cid)
			this.last_modal_view_id = out.body.view_id
			this.$root.$emit('showConversationModal', {
				user_id: sb.usersOfConvo(convo)[0],
				view_id: out.body.view_id,
				url: out.body.url,
			})
		},

		updateMentionsState() {
			if (!this.editor) return
			if (!this.useMention) return
			const editorWindow = this.editor._window || window
			const range = editorWindow.document.createRange()
			const selection = $getSelection()
			//if (!$isRangeSelection(selection) || !selection.isCollapsed() || range === null) {
			if (!$isRangeSelection(selection) || range === null) {
				// somehow selection.isCollapsed() = true when conposing @du when using Ubuntu Vietnamses Unikey Airbus
				// Doen't test in other Window unikey or MacOS Unikey yet.
				this.closeMentionsDropdown()
				return
			}
			let text = getTextUpToAnchor(selection)
			if (text === null || !text) {
				this.closeMentionsDropdown()
				return
			}
			let match = checkForAtSignMentions(text)
			if (match) {
				let isRangePositioned = tryToPositionRange(match.leadOffset, range, editorWindow)
				this._currentMentionNode = selection.anchor.getNode()
				this._prefixMentionText = text.substr(0, match.leadOffset)
				this._mentionText = match.matchingString
				this._mentionRange = range

				let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent')
				if (this.has_mention_all) agents.unshift({id: 'all', fullname: 'all'})
				agents = lo.filter(agents, (agent) => {
					if (agent.type !== 'agent') return false
					let fullname = sb.unicodeToAscii(agent.fullname || '').toLowerCase()
					let keyword = sb.unicodeToAscii(this._mentionText || '').toLowerCase()
					return fullname.indexOf(keyword) > -1
				})
				this._agents = agents
				if (lo.size(this._agents)) {
					this.openMentionsDropdown()
				} else {
					this.closeMentionsDropdown()
				}
			} else {
				this.closeMentionsDropdown()
			}
		},

		closeMentionsDropdown() {
			this.isMentionsDropdownOpened = false
			this._allowOpenMentions = false
		},

		openMentionsDropdown() {
			// _allowOpenMentions set to false no avoid click in text => display dropdown
			if (!this._allowOpenMentions) return
			this.isMentionsDropdownOpened = true
		},

		renderMentionsDropdown() {
			if (!this.useMention) return
			return (
				<LexicalMentionDropdown
					open={this.isMentionsDropdownOpened}
					range={this._mentionRange}
					agents={this._agents}
					vOn:close={this.closeMentionsDropdown}
					vOn:select={this.onSelectMention}
				/>
			)
		},

		onSelectMention(agent) {
			if (!this.editor) return
			this.editor.update(() => {
				const mentionNode = $createMentionNode('@' + agent.fullname, agent.id)
				let textNode = $createTextNode(this._prefixMentionText)
				let spaceTextNode = $createTextNode(' ')
				if (this._currentMentionNode) {
					this._currentMentionNode.remove()
					$insertNodes([textNode, mentionNode, spaceTextNode])
					spaceTextNode.select()
				}
			})
		},
	},

	render() {
		let $tabs = (
			<div class='lexical_editor_tabs btn__switch'>
				<div
					class={{
						btn: true,
						btn__secondary: this.mode === 'editor',
						btn__outline_secondary: this.mode !== 'editor',
						lexical_editor_tab_item: true,
						btn__xs: true,
					}}
					vOn:click={() => this.toggleMode('editor')}
				>
					{this.$t('rich_text')}
				</div>
				<div
					class={{
						btn: true,
						btn__secondary: this.mode === 'source',
						btn__outline_secondary: this.mode !== 'source',
						lexical_editor_tab_item: true,
						btn__xs: true,
					}}
					vOn:click={() => this.toggleMode('source')}
				>
					HTML
				</div>
				<div
					class={{
						btn: true,
						btn__secondary: this.mode === 'preview',
						btn__outline_secondary: this.mode !== 'preview',
						lexical_editor_tab_item: true,
						btn__xs: true,
					}}
					vOn:click={() => this.toggleMode('preview')}
				>
					{this.$t('preview')}
				</div>
			</div>
		)
		if (!this.html || this.no_preview_mode) $tabs = null
		let drag_style =
			'flex-direction: column; position: absolute; top: 0; width: 100%; height: 100%; background: #ffffffd1; visibility: hidden;'
		if (this.is_dragging) drag_style += 'visibility: visible; cursor: pointer;'
		return (
			<div class='lexical_editor_tabs_wrapper' ref='wrapper'>
				{$tabs}
				<div
					class='lexical_editor_wrapper'
					style={`${this.is_dragging ? 'border: 1px solid white' : ''} + 'position: relative;'`}
				>
					{this.$slots.default}
					<div class='lexical_editor_input_wrapper'>
						<div
							ref='editor'
							vOn:focus={(e) => this.$emit('focus', e)}
							vOn:blur={(e) => this.$emit('blur', e)}
							contentEditable
							class={{lexical_editor_input: true, html: this.html}}
							spellcheck='false'
						></div>
						{this.renderPlaceholder()}
					</div>
					{this.renderErrorOverlay()}
					{this.useTemplate && (
						<MessageTemplates
							ref='message_template'
							query={this.templateQuery}
							vOn:modal={this.onModal}
							vOn:choose={this.onSelectMessageTemplate}
							connectorType={this.template_connector_type}
						/>
					)}
					{this.useSuggestion && (
						<Suggestion ref='suggestion' query={this.suggestionQuery} vOn:choose={this.applySuggestion} />
					)}

					{this.renderMentionsDropdown()}
					{this.renderToolbar()}
					{this.renderFiles()}
					{this.renderGenericTemplates()}
					{this.renderEditLinkModal()}
					{this.renderEditDynamicFieldDropdown()}
					{this.renderInsertImageModal()}
					{this.renderSourceEditor()}
					{this.renderPreviewBlock()}
					{this.renderProductModal()}
					{/*
					<div ref='dragging_background' class='d-flex justify-content-center align-items-center ' style={drag_style}>
						{this.is_uploading ? (
							<Spinner size='30' />
						) : (
							<Fragment>
								{this.html && <img style='width: 180px; height: auto;' src={require('../assets/img/dragging.png')} />}
								<div class='text__muted'>Drop the mouse to upload</div>
							</Fragment>
						)}
					</div>
          */}
				</div>
				{this.renderFloatTextFormatPopup()}
				{this.renderFloatEditLinkPopup()}
			</div>
		)
	},
}

function findFirstTextNode(root) {
	if (!root) return

	let result
	let children = root.getChildren()
	if (lo.size(children)) result = children[0]
	if (!result) return
	if ($isTextNode(result)) return result
	if (!result.getChildren) return
	children = result.getChildren()
	if (lo.size(children)) result = children[0]
	if ($isTextNode(result)) return result
}

window.sb = sb

// this is code test for mention plugins
//
const PUNC = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;'
const TRIGGERS = ['@'].join('')

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]'

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
	'(?:' +
	'\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
	' |' + // E.g. " " in "Josh Duck"
	'[' +
	PUNC +
	']|' + // E.g. "-' in "Salier-Hellendag"
	')'

const LENGTH_LIMIT = 75

const AtSignMentionsRegex = new RegExp(
	'(^|\\s|\\()(' + '[' + TRIGGERS + ']' + '((?:' + VALID_CHARS + VALID_JOINS + '){0,' + LENGTH_LIMIT + '})' + ')$',
)

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
	'(^|\\s|\\()(' + '[' + TRIGGERS + ']' + '((?:' + VALID_CHARS + '){0,' + ALIAS_LENGTH_LIMIT + '})' + ')$',
)

function checkForAtSignMentions(text, minMatchLength = 0) {
	let match = AtSignMentionsRegex.exec(text)

	if (match === null) {
		match = AtSignMentionsRegexAliasRegex.exec(text)
	}
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1]

		const matchingString = match[3]
		if (matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: match[2],
			}
		}
	}
	return null
}

function getTextUpToAnchor(selection) {
	const anchor = selection.anchor
	if (anchor.type !== 'text') {
		return null
	}
	const anchorNode = anchor.getNode()
	if (!anchorNode.isSimpleText()) {
		return null
	}
	const anchorOffset = anchor.offset
	return anchorNode.getTextContent().slice(0, anchorOffset)
}
function tryToPositionRange(leadOffset, range, editorWindow) {
	const domSelection = editorWindow.getSelection()
	if (domSelection === null || !domSelection.isCollapsed) {
		return false
	}
	const anchorNode = domSelection.anchorNode
	const startOffset = leadOffset
	const endOffset = domSelection.anchorOffset

	if (anchorNode == null || endOffset == null) {
		return false
	}

	try {
		range.setStart(anchorNode, startOffset)
		range.setEnd(anchorNode, endOffset)
	} catch (error) {
		return false
	}

	return true
}

function getSelectedNode(selection) {
	const anchor = selection.anchor
	const focus = selection.focus
	const anchorNode = selection.anchor.getNode()
	const focusNode = selection.focus.getNode()
	if (anchorNode === focusNode) {
		return anchorNode
	}
	const isBackward = selection.isBackward()
	if (isBackward) {
		return $isAtNodeEnd(focus) ? anchorNode : focusNode
	} else {
		return $isAtNodeEnd(anchor) ? anchorNode : focusNode
	}
}
