import store from '@sb/store'
import sb from '@sb/util'

const WIDTH = 500

export default {
	name: 'onboarding-modal',
	data() {
		return {
			open: false,
			step: 1,
		}
	},

	async mounted() {
		await this.loadData()
		this.setInitialState()
	},

	methods: {
		loadData() {
			return Promise.all([store.fetchIntegrations(), store.fetchSites()])
		},

		jump(step) {
			this.step = step
		},

		jumpBack() {
			if (this.step <= 1) return
			this.step = this.step - 1
		},

		jumpFoward() {
			if (this.step >= 3) return
			this.step = this.step + 1
		},

		setInitialState() {
			this.open = store.shouldShowOnboarding()
			if (this.open) {
				store.addClarityTracingScript()
				setTimeout(() => {
					if (window.clarity) {
						window.clarity('set', 'sbz_record_type', 'onboarding')
					}
				}, 100)
			}
		},

		renderStep1Content() {
			let style = 'left: 0;'
			if (this.step !== 1) style = `left: -${WIDTH}px`

			return (
				<div class='onboarding_modal_content_container' style={style}>
					<div class='onboarding_modal_content_inner'>
						<div class='text__center'>
							<div class='onboarding_modal_title'>{'Cửa sổ chat trên website'}</div>
							<div class='onboarding_modal_desc'>
								{'Tạo cửa sổ với màu sắc riêng của bạn, chuyển đổi truy cập thành thành doanh thu'}
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderStep2Content() {
			let style = 'left: 0;'
			if (this.step < 2) style = `left: ${WIDTH}px`
			if (this.step > 2) style = `left: -${WIDTH}px`

			return (
				<div class='onboarding_modal_content_container' style={style}>
					<div class='onboarding_modal_content_inner'>
						<div class='text__center'>
							<div class='onboarding_modal_title'>{'Tương tác trên Fanpage, Zalo'}</div>
							<div class='onboarding_modal_desc'>
								{'Nhận và trả lời tin nhắn của khách hàng trên nhiều Fanpage, ZaloOA chỉ trên 1 màn hình Subiz'}
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderStep3Content() {
			let style = 'left: 0;'
			if (this.step !== 3) style = `left: ${WIDTH}px`
			return (
				<div class='onboarding_modal_content_container' style={style}>
					<div class='onboarding_modal_content_inner'>
						<div class='text__center'>
							<div style='font-size: 24px'>
								<strong>{'Chuyển đổi khách tự động'}</strong>
							</div>
							<div class='onboarding_modal_desc'>
								{'Sử dụng bot và popup để tự động thu thập SĐT và Email của khách tất cả mọi lúc'}
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderNavigationDots() {
			return (
				<div class='d-flex justify-content-center mt-4'>
					<div class={{onboarding_modal_nav_dot: true, active: this.step === 1}} vOn:click={() => this.jump(1)} />
					<div class={{onboarding_modal_nav_dot: true, active: this.step === 2}} vOn:click={() => this.jump(2)} />
					<div class={{onboarding_modal_nav_dot: true, active: this.step === 3}} vOn:click={() => this.jump(3)} />
				</div>
			)
		},

		renderActions() {
			if (this.step === 1) {
				return (
					<div class='mt-4 d-flex align-items-center' key='step_1'>
						<div class='link link__secondary' style='width: 110px' vOn:click={() => (this.open = false)}>
							{'Bỏ qua'}
						</div>
						<div
							class='btn btn__primary align-items-center ml-4 flex__1 justify-content-center'
							style='display: inline-flex'
							vOn:click={this.jumpFoward}
						>
							{'Tiếp tục'}
							<Icon name='arrow-right' size='18' stroke-width='2' class='ml-2' />
						</div>
					</div>
				)
			}
			if (this.step === 2) {
				return (
					<div class='mt-4 d-flex' key='step_2'>
						<div class='btn btn__white' style='width: 110px' vOn:click={this.jumpBack}>
							{this.$t('back')}
						</div>
						<div
							vOn:click={this.jumpFoward}
							class='btn btn__primary align-items-center ml-4 flex__1 justify-content-center'
							style='display: inline-flex'
						>
							{'Tiếp tục'}
							<Icon name='arrow-right' size='18' stroke-width='2' class='ml-2' />
						</div>
					</div>
				)
			}
			if (this.step === 3) {
				let items = [
					{
						id: 'facebook',
						label: 'Tích hợp Fanpage',
						img: require('./assets/img/facebook_channel.svg'),
					},
					{
						id: 'zalo',
						label: 'Tích hợp ZaloOA',
						img: require('./assets/img/zalo_channel.svg'),
					},
					{
						id: 'instagram',
						label: 'Tích hợp Instagram',
						img: require('./assets/img/instagram.svg'),
					},
				]
				return (
					<div class='mt-4 d-flex' key='step_3'>
						<div class='btn btn__white no-shrink' style='width: 110px' vOn:click={this.jumpBack}>
							{this.$t('back')}
						</div>
						<div class='ml-4 flex__1 d-flex'>
							<div
								vOn:click={() => this.callToAction('website')}
								class='btn btn__primary align-items-center flex__1 justify-content-center'
								style='display: inline-flex; margin-right: 0; border-top-right-radius: 0; border-bottom-right-radius: 0'
							>
								{'Tích hơp website'}
							</div>
							<Dropdown
								mode='custom'
								dropdown_width={200}
								items={items}
								vOn:select={(item) => this.callToAction(item.id)}
							>
								<div
									class='btn btn__primary'
									style='border-top-left-radius: 0; border-bottom-left-radius: 0; padding-left: 3px; padding-right: 3px;'
								>
									<Icon name='chevron-down' size='16' stroke-width='2' />
								</div>
							</Dropdown>
						</div>
					</div>
				)
			}
		},

		callToAction(name) {
			this.open = false
			if (name === 'website') {
				this.$router.push({name: 'site-install'})
				return
			}
			if (name === 'zalo') {
				this.$router.push({name: 'settings.zalo', query: {onboarding: 'true'}})
				return
			}
			if (name === 'facebook') {
				this.$router.push({name: 'settings.messenger', query: {onboarding: true}})
				return
			}
			if (name === 'instagram') {
				this.$router.push({name: 'settings.instagram', query: {onboarding: true}})
				return
			}
			this.$router.push({name: `site-install`, query: {onboarding: 'true'}})
		},

		renderStep1Image() {
			let style = 'left: 0;'
			if (this.step !== 1) style = `left: -${WIDTH}px`

			return <img src={require('./assets/img/onboarding/slide1.png')} class='onboarding_modal_img' style={style} />
		},

		renderStep2Image() {
			let style = 'left: 0;'
			if (this.step < 2) style = `left: ${WIDTH}px`
			if (this.step > 2) style = `left: -${WIDTH}px`

			return (
				<img src={require('./assets/img/onboarding/multu channel.png')} class='onboarding_modal_img' style={style} />
			)
		},

		renderStep3Image() {
			let style = 'left: 0;'
			if (this.step !== 3) style = `left: ${WIDTH}px`

			return <img src={require('./assets/img/onboarding/automate.png')} class='onboarding_modal_img' style={style} />
		},
	},

	render() {
		if (!this.open) return null

		return (
			<div class='modal'>
				<div class='modal__overlay' style='background-color: rgba(0,0,0,0.35)' />
				<div class='modal__container'>
					<div class='modal__background'>
						<div class='modal_content onboarding_modal_content'>
							<div class='onboarding_modal_img_wrapper'>
								<div class='onboarding_modal_img_inner'>
									{this.renderStep1Image()}
									{this.renderStep2Image()}
									{this.renderStep3Image()}
								</div>
								{this.step > 1 && (
									<div class='onboarding_modal_img_navigation left' vOn:click={this.jumpBack}>
										<Icon name='chevron-left' size='36' stroke-width='2' />
									</div>
								)}
								{this.step < 3 && (
									<div class='onboarding_modal_img_navigation right' vOn:click={this.jumpFoward}>
										<Icon name='chevron-right' size='36' stroke-width='2' />
									</div>
								)}
							</div>
							{this.renderNavigationDots()}
							<div class='onboarding_modal_content_wrapper'>
								{this.renderStep1Content()}
								{this.renderStep2Content()}
								{this.renderStep3Content()}
							</div>
							<div class='pl-5 pr-5 pb-4'>{this.renderActions()}</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}
